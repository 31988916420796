import Modal from "../components/Modal/Modal";
import { Col, Form, Input, Row, Select  } from "antd";
import Button from "../components/Button";
import { useState } from "react";
import { Mode } from "../utils/types";
import { SocialFeedMapping } from "../utils/types";
import PublisherSelect from "../pages/DomainSelection/PublisherSelect";
import DomainSelect from "../pages/DomainSelection/DomainSelect";
import { IDS } from "../utils/elementsIds";

interface SocialFeedMappingModalProps {
  close: () => void;
  onFinish: (data: any) => void;
  mode: Mode;
  data?: SocialFeedMapping;
}

const SocialFeedMappingModal = (props: SocialFeedMappingModalProps) => {
  const { close, onFinish, mode, data } = props;
  const [dataSocialFeedMapping, setDataSocialFeedMapping] = useState<SocialFeedMapping>(data ? {...data } : {} as SocialFeedMapping);
  const initialValues = {
    social_feed_platform: dataSocialFeedMapping.social_feed_platform ?? "",
    social_feed_account: dataSocialFeedMapping.social_feed_account ?? "",
    social_feed_tag: dataSocialFeedMapping.social_feed_tag ?? "",
    social_feed_category: dataSocialFeedMapping.social_feed_category ?? ""
  };
  let final = {};
  if(mode === "new") final = dataSocialFeedMapping; 
  else final = { ...dataSocialFeedMapping };

  return (
    <Modal
      width={"60%"}
      title={<h2>{mode === "new" ? "Add New Social Feed Mapping" : "Edit Social Feed Mapping"}</h2>}
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
    >
      <Form name="basic" autoComplete="off" layout="vertical" requiredMark onFinish={() => onFinish(final)} initialValues={initialValues} style={{ overflow: "hidden" }}>
      {mode === "new" && (<>
      <Row gutter={[24, 24]}>
       <Col span={8}>
            <Form.Item
              label="Choose Publisher"
              name="publisher"
              rules={[
                {
                  required: !dataSocialFeedMapping.pub_id,
                  message: "Please select publisher",
                },
              ]}
            >
              <PublisherSelect
                id={IDS.NEW_TAG.PUBLISHER_SELECT}
                mode="new"
                onSelect={(pub_id, pub_key) => {
                  setDataSocialFeedMapping({
                    ...dataSocialFeedMapping,
                    pub_id: pub_id,
                    pub_key: pub_key
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Choose Domain"
              name="domain"
              rules={[
                {
                  required: !dataSocialFeedMapping.domain_id,
                  message: "Please select domain",
                },
              ]}
            >
              <DomainSelect
                id={IDS.NEW_TAG.DOMAIN_SELECT}
                onSelect={(value: any) => {
                  setDataSocialFeedMapping({
                    ...dataSocialFeedMapping,
                    domain_id: value,
                  });
                }}
              />
            </Form.Item>
          </Col>
       </Row>
       </>
        )}
        <Row gutter={[24, 24]}>
          {(<>
            <Col span={8}>
              <Form.Item
                label="Social Feed Platform"
                name="social_feed_platform"
                rules={[
                  {
                    required: true,
                    message: "Please select Social Feed Platform",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select Social Feed Platform"
                  value={dataSocialFeedMapping.social_feed_platform}
                  onChange={(value) => {
                    setDataSocialFeedMapping({
                      ...dataSocialFeedMapping,
                      social_feed_platform: value || "",
                    });
                  }}
                  options={[
                    { value: 'instagram', label: 'Instagram' },
                    { value: 'tiktok', label: 'Tiktok' },
                    { value: 'twitter', label: 'Twitter' },
                    { value: 'youtube_shorts', label: 'YouTube Shorts' },
                    { value: 'bluesky', label: 'Bluesky' },
                    { value: 'linkedin_profile', label: 'Linkedin Profile' },
                    { value: 'linkedin_company', label: 'Linkedin Company' },
                    { value: 'pinterest_user_pins', label: 'Pinterest User Pins' },
                    { value: 'pinterest_board_pins', label: 'Pinterest Board Pins' }
                  ]}
                />
              </Form.Item>
            </Col>
          <Col span={8}>
            <Form.Item
              label="Social Feed Account"
              name="social_feed_account"
              rules={[
                {
                  required: true,
                  message: "Please type Social Feed Account",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Type Social Feed Account"
                value={dataSocialFeedMapping.social_feed_account}
                onChange={(e) => {
                  if (e.target.value !== "")
                    setDataSocialFeedMapping({
                      ...dataSocialFeedMapping,
                      social_feed_account: e.target.value,
                    });
                  else
                    setDataSocialFeedMapping({
                      ...dataSocialFeedMapping,
                      social_feed_account: "",
                    });
                }}
              ></Input>
            </Form.Item>
          </Col>
          </>
        )}
        </Row>
        <Row gutter={[24, 24]}>
          {(<>
          <Col span={8}>
            <Form.Item
              label="Social Feed Tag"
              name="social_feed_tag"
              rules={[
                {
                  required: true,
                  message: "Please type Social Feed Tag",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Type Social Feed Tag"
                value={dataSocialFeedMapping.social_feed_tag}
                onChange={(e) => {
                  if (e.target.value !== "")
                    setDataSocialFeedMapping({
                      ...dataSocialFeedMapping,
                      social_feed_tag: e.target.value,
                    });
                  else
                    setDataSocialFeedMapping({
                      ...dataSocialFeedMapping,
                      social_feed_tag: "",
                    });
                }}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Social Feed Category"
              name="social_feed_category"
              rules={[
                {
                  required: true,
                  message: "Please type Social Feed Category",
                },
              ]}
            >
              <Select
                  size="large"
                  placeholder="Select Social Feed Category"
                  value={dataSocialFeedMapping.social_feed_category}
                  onChange={(value) => {
                    setDataSocialFeedMapping({
                      ...dataSocialFeedMapping,
                      social_feed_category: value || "",
                    });
                  }}
                  options={[
                    { value: 'category', label: 'Category' },
                    { value: 'channel', label: 'Channel' },
                    { value: 'competition', label: 'Competition' },
                    { value: 'league', label: 'League' },
                    { value: 'news', label: 'News' },
                    { value: 'person', label: 'Person' },
                    { value: 'team', label: 'Team' }
                  ]}
                />
            </Form.Item>
          </Col>
          </>
        )}
        </Row>
        <Row justify="center">
          <Button
            type="primary"
            title={mode === "new" ? "Add Social Feed Mapping" : "Edit Social Feed Mapping"}
            htmlType="submit"
          ></Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default SocialFeedMappingModal;
