import client from "../client";

export const getRevenue = async (date: any) => {
  const res = await client.get("/revenue", {
    params: { date },
  });
  return res.data;
};

export const saveRevenue = async (date: string, data: any) => {
  const res = await client.post("/revenue/update", { date: date, json: data });
  return res.data;
};

export const exportRevenue = async (date: string, data: any) => {
  const res = await client.post("/revenue/export", { date: date, json: data });
  return res.data;
};