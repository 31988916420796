import React, { useEffect, useState, useCallback } from "react";
import { Row, Table, DatePicker, InputNumber, Form, notification } from "antd";
import Button from "../../components/Button";
import { SaveOutlined } from '@ant-design/icons';
import PureCard from "../../components/PureCard/PureCard";
import dayjs from "dayjs";
import { NotificationType } from "../../utils/notifictions";
import { getAdjustments, exportAdjustment } from "../../api/services/Adjustments";
import { v4 as uuidv4 } from 'uuid';
import "./styles.scss";

interface DataType {
  id: number;
}

const EditableCell: React.FC<{
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof DataType;
  record: any;
  handleSave: (record: any, values: any) => void;
}> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState("");

  const save = async (e: any) => {
    try {
      setValue(e);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if(value !== ""){
        const values = await form.validateFields();
        if(typeof handleSave === "function") handleSave(record, values);
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [value, form, record, handleSave]);

  let childNode = children;
  if (editable) {
    const def = JSON.stringify(children),
          arr = JSON.parse(def),
          initialValues = {
            [dataIndex]: arr[1] || ''
          };
     
    childNode = <Form form={form} component={false} initialValues={initialValues}>
        <Form.Item name={dataIndex} style={{ margin: 0, width: "100%" }}>
          <InputNumber onChange={save} formatter={(value) => thousands(value)} style={{ width: "100%" }} />
        </Form.Item>
      </Form>;
  }

  return <td {...restProps}>{childNode}</td>;
};


const thousands = (num: any) => {
  return Number(num).toLocaleString();
}

const convertToNumber = (formattedString: any) => {
  try {
    const sanitizedString = formattedString.replace(/[^0-9.,]/g, '');
    return parseFloat(sanitizedString.replace(/,/g, ''));
  } catch (error) {
    return formattedString;
  }
}

const Adjustments = () => {
  const [originalDate, setOriginalDate] = useState<any>();
  const [dataLog, setDataLog] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };

  const getData = useCallback(async (date: any) => {
    setDataLog([]);
    setIsLoading(true);
    let response = await getAdjustments(date);

    response = response.map((element: any) => {
      element.widget_load = thousands(element.widget_load);
      element.total_revenue = thousands(element.total_revenue);
      element.adjusted_widget_loads = thousands(element.adjusted_widget_loads);
      element.adjusted_publisher_revenue = thousands(element.adjusted_publisher_revenue);
      element.approved = element.approved.toString();
      return element;
    });
    
    setDataLog(response);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (originalDate) getData(originalDate);
  }, [originalDate, getData]);

  const handleSave = (row: any, val: any) => {
    let newData = [...dataLog];
    const item = { ...newData[row], ...val };
    newData[row] = item;
    setDataLog(newData);
  };

  const getColumns = () => {
    return [
      {
        title: "Domain id",
        key: "domain_id",
        dataIndex: "domain_id"
      },
      {
        title: "Domain name",
        dataIndex: "domain_name"
      },
      {
        title: "Platform",
        dataIndex: "platform"
      },
      {
        title: "Country",
        dataIndex: "country"
      },
      {
        title: "Widget loads",
        dataIndex: "widget_load"
      },
      {
        title: "Total revenue",
        dataIndex: "total_revenue"
      },
      {
        title: "Approved",
        dataIndex: "approved"
      },
      {
        title: "Adjusted widget loads",
        dataIndex: "adjusted_widget_loads",
        editable: true
      },
      {
        title: "Adjusted publisher revenue",
        dataIndex: "adjusted_publisher_revenue",
        editable: true
      }
    ];
  };
  
  const onChangeDate = (date: any) => {
    date = date ? dayjs(date).format('YYYY-MM-DD') : dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    setOriginalDate(date);
  };

  const disabledDate = (current: any) => {
    return current && current >= dayjs().subtract(1, 'day').startOf('day');
  };

  const mergedColumns = getColumns().map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (_: any,record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const dataLogWithKeys = dataLog.map((item) => ({
    ...item,
    uniqueKey: uuidv4(),
  }));

  let totals = dataLog.reduce(
    (acc, item) => {
      acc.widget_load += convertToNumber(item.widget_load);
      acc.total_revenue += convertToNumber(item.total_revenue);
      acc.adjusted_publisher_revenue += convertToNumber(item.adjusted_publisher_revenue);
      acc.adjusted_widget_loads += convertToNumber(item.adjusted_widget_loads);
      return acc;
    },
    { widget_load: 0, total_revenue: 0, adjusted_publisher_revenue: 0, adjusted_widget_loads: 0 }
  );

  totals = Object.fromEntries(
    Object.entries(totals).map(([key, value]) => [key, thousands(value)])
  );

  return (
    <div className="adjustments">
      <PureCard>
        <Row style={{ marginBottom: 24 }}>
          <div className="container">
            <div className="center-container">
            <DatePicker size="large" onChange={onChangeDate} format="DD/MM/YYYY" disabledDate={disabledDate} allowClear={false} />
            </div>
            <Button
              type="primary"
              size="large"
              title="Save"
              icon={<SaveOutlined />}
              onClick={() => {
                exportAdjustment(originalDate, dataLog);
                openNotificationWithIcon("success", "Adjustment added successfully");
              }}
            ></Button>
          </div>
        </Row>

        {dataLog.length > 0 && (
          <>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              columns={mergedColumns}
              dataSource={dataLogWithKeys}
              loading={isLoading}
              rowKey="uniqueKey"
              pagination={false}
              scroll={{ x: 1000 }}
            />
            <br /><br />
            <Table
              bordered
              columns={[
                {
                  title: "Widget loads",
                  dataIndex: "widget_load"
                },
                {
                  title: "Total revenue",
                  dataIndex: "total_revenue"
                },
                {
                  title: "Adjusted widget loads",
                  dataIndex: "adjusted_widget_loads"
                },
                {
                  title: "Adjusted publisher revenue",
                  dataIndex: "adjusted_publisher_revenue"
                }
              ]}
              dataSource={[totals]}
              loading={isLoading}
              rowKey="widget_load"
              pagination={false}
              title={() => 'Total'}
            />

          </>
        )}

        {!dataLog.length && (
          <Table
            bordered
            columns={mergedColumns}
            loading={isLoading}
            rowKey="domain_id"
            pagination={false}
          />
        )}

        {contextHolder}
      </PureCard>
    </div>
  );
};

export default Adjustments;