import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Form, Input, Select, Button, Typography, Spin, Upload, Modal, Tooltip } from 'antd';
import { captureUrl, uploadFile, saveData, addPost } from '../../api/services/DemoCreator';
import { UploadOutlined, CopyTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
const { Title } = Typography;
const { Option } = Select;

const DemoCreator: React.FC = () => {
  const [url, setUrl] = useState(null),
        [loading, setLoading] = useState(false),
        [device, setDevice] = useState(null),
        [formUrl] = Form.useForm(),
        [formFile] = Form.useForm(),
        iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {      
      switch (event.data?.action) {
        case "SAVE_DATA":
          console.log("Message received from iframe child:", event.data);
          try {
            if (event?.data?.data?.actions.length > 0) {
              setLoading(true);
              const res = await saveData(event.data);
              if (res?.status === "ok") {
                setLoading(false);
                const finalUrl = `${res.url.includes("/mobile") ? 'https://site.carambo.la/extension/preview/simulator-max.html?url=' : ''}${res.url}?random=${Math.floor(Math.random() * 1001)}`;
                Modal.success({
                  title: 'Demo Page URL',
                  width: 500,
                  content: (
                    <div>
                      <p>
                        <CopyTwoTone
                          onClick={() => copy(finalUrl)}
                          style={{ fontSize: '18px', marginRight: '5px', float: 'left' }}
                        />
                        <a href={finalUrl} target='_blank' rel="noopener noreferrer">
                          {finalUrl}
                        </a>
                      </p>
                    </div>
                  ),
                  onOk() {
                    setUrl(null);
                    setLoading(false);
                    setDevice(null);
                  }
                });
              } else {
                throw new Error('Save failed!');
              }
            }
          } catch (error) {
            console.error('Error saving file:', error);
            setUrl(null);
            setLoading(false);
            setDevice(null);
            Modal.error({
              title: 'Demo Creator - Error',
              width: 400,
              content: (
                <div>
                  <p>Error: Unable to save the file.</p>
                </div>
              ),
              onOk() {},
            });
          }
          break;
      
        case "RELOAD_DATA":
          setUrl(null);
          setLoading(false);
          setDevice(null);
          break;
      
        case "INFO":
          setUrl(null);
          setLoading(false);
          setDevice(null);
          Modal.info({
            title: 'Demo Creator - Info',
            width: 500,
            content: (
              <div>
                <p>{event?.data?.data?.txt ?? 'No information available'}</p>
              </div>
            ),
            onOk() {},
          });
          break;
      
        case "ADD_DATA":
          await addPost(event.data);
          break;
      }
      
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const cleanAndValidateUrl = (url: string) => {
    url = url.split('?')[0];
    url = url.replace(/(instagram\.com\/)reel\//, '$1p/');
    const regex = /^(https?:\/\/)?(www\.)?(instagram\.com\/p\/[A-Za-z0-9_-]+|x\.com\/[A-Za-z0-9_-]+\/status\/\d+|tiktok\.com\/@[\w.-]+\/video\/\d+|youtube\.com\/shorts\/[A-Za-z0-9_-]+)\/?$/;
    return regex.test(url) ? url : false;
  }

  const getLastSegment = (url: string) => {
    url = url.replace(/\/+$/, "");
    return url.substring(url.lastIndexOf("/") + 1);
  }

  const onFinishUrl = async(values: any) => {
    let { socialFeedAccount, insertUrl, firstPost } = values;

    if(insertUrl.includes('units.carambo.la') || insertUrl.includes('/demo_creator/')){
      Modal.info({
        title: 'Demo Page - Invalid URL',
        width: 400,
        content: (
          <div>
            <p>Unable to process the current URL.</p>
          </div>
        ),
        onOk() {}
      });
      return false;
    }

    if (socialFeedAccount && !socialFeedAccount.startsWith('@')) {
      socialFeedAccount = '@' + socialFeedAccount;
    }

    if(firstPost){
      let cleanedFirstPost = cleanAndValidateUrl(firstPost);
      if(cleanedFirstPost === false) {
        Modal.error({
          title: 'Demo Page - Invalid First Post URL',
          width: 500,
          content: (
            <div>
              <p>
                Example of valid post URLs:
                https://www.instagram.com/p/DFl7VRIg6_u/ 
                https://x.com/nypost/status/1886642878446714988 
                https://www.tiktok.com/@cbs/video/7467046817629211934 
                https://www.youtube.com/shorts/itIRfoap3AA
              </p>
            </div>
          ),
          onOk() {}
        });
        return false;
      }
      else {
        const embed = getLastSegment(cleanedFirstPost);
        const hostname = new URL(cleanedFirstPost).hostname;

        switch (true) {
          case hostname.includes("instagram.com"):
            cleanedFirstPost = `https://www.instagram.com/p/${embed}/`;
            break;
          case hostname.includes("x.com"):
            cleanedFirstPost = `https://twitframe.com/show?url=https://twitter.com/xxxxx/status/${embed}`;
            break;
          case hostname.includes("tiktok.com"):
            cleanedFirstPost = `https://www.tiktok.com/embed/v2/${embed}`;
            break;
          case hostname.includes("youtube.com"):
            cleanedFirstPost = `https://www.youtube.com/embed/${embed}`;
            break;
        }

        values = { ...values, firstPost: cleanedFirstPost }; 
      }
    }

    setLoading(true);
    try {
        const response = await captureUrl(values);
        if (response?.status && response.status === "ok") {
          setUrl(response.url);
          setDevice(values.device);
          setLoading(false);
        } else {
          throw new Error('Upload failed!');
        }
    } catch (error) {
        console.error('Error uploading file:', error);
        setUrl(null);
        setLoading(false);
        setDevice(null);
        Modal.error({
          title: 'Demo Creator - Error',
          width: 400,
          content: (
            <div>
              <p>Error: Unable to process the current URL.</p>
            </div>
          ),
          onOk() { },
        });
    }
  };

  const onFinishFile = async (values: any) => {
    let { socialFeedAccount, firstPost } = values;
    if (socialFeedAccount && !socialFeedAccount.startsWith('@')) {
      socialFeedAccount = '@' + socialFeedAccount;
    }
  
    const fileList = values.htmlFile;
    if (!fileList || fileList.length === 0) {
      console.error('No file uploaded!');
      return;
    }
  
    const file = fileList[0].originFileObj;
  
    if(firstPost){
      let cleanedFirstPost = cleanAndValidateUrl(firstPost);
      if(cleanedFirstPost === false) {
        Modal.error({
          title: 'Demo Page - Invalid First Post URL',
          width: 500,
          content: (
            <div>
              <p>
                Example of valid post URLs:
                https://www.instagram.com/p/DFl7VRIg6_u/ 
                https://x.com/nypost/status/1886642878446714988 
                https://www.tiktok.com/@cbs/video/7467046817629211934 
                https://www.youtube.com/shorts/itIRfoap3AA
              </p>
            </div>
          ),
          onOk() {}
        });
        return false;
      }
      else {
        const embed = getLastSegment(cleanedFirstPost);
        const hostname = new URL(cleanedFirstPost).hostname;

        switch (true) {
          case hostname.includes("instagram.com"):
            cleanedFirstPost = `https://www.instagram.com/p/${embed}/`;
            break;
          case hostname.includes("x.com"):
            cleanedFirstPost = `https://twitframe.com/show?url=https://twitter.com/xxxxx/status/${embed}`;
            break;
          case hostname.includes("tiktok.com"):
            cleanedFirstPost = `https://www.tiktok.com/embed/v2/${embed}`;
            break;
          case hostname.includes("youtube.com"):
            cleanedFirstPost = `https://www.youtube.com/embed/${embed}`;
            break;
        }

        values = { ...values, firstPost: cleanedFirstPost }; 
      }
    }

    setLoading(true);
    try {
      const response = await uploadFile(file, values);
      if (response?.status && response.status === 'ok') {
        setUrl(response.url);
        setDevice(values.device);
        setLoading(false);
      } else {
        throw new Error('Upload failed!');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setUrl(null);
      setLoading(false);
      setDevice(null);
      Modal.error({
        title: 'Demo Creator - Error',
        width: 400,
        content: (
          <div>
            <p>Error: Unable to process the file.</p>
          </div>
        ),
        onOk() { },
      });
    }
  };

  if(loading) {
    return <Spin size="large" fullscreen />;
  }

  if(url){
    return <>
      <iframe ref={iframeRef} src={url + "&random=" + Math.floor(Math.random() * 1001)} height="93%" width={device === "mobile" ? "430px" : "100%" } style={{margin: "0 auto"}} title='Demo Creator'></iframe>
    </>;
  } else {
    const title = <span>
      Example of valid post URLs:<br />
      https://www.instagram.com/p/DFl7VRIg6_u/
      https://x.com/nypost/status/1886642878446714988
      https://www.tiktok.com/@cbs/video/7467046817629211934
      https://www.youtube.com/embed/itIRfoap3AA
    </span>;

    const insertUrlTab = <Form
                            form={formUrl}
                            layout="vertical"
                            onFinish={onFinishUrl}
                          >
                            <Form.Item
                              name="insertUrl"
                              rules={[{ required: true, message: 'Please enter a URL' }]}
                            >
                              <Input placeholder="Insert URL" size="large" />
                            </Form.Item>

                            <Form.Item
                              name="device"
                              style={{ width: 200 }}
                              rules={[{ required: true, message: 'Please select a device type' }]}
                            >
                              <Select placeholder="Select Device" size="large">
                                <Option value="desktop">Desktop</Option>
                                <Option value="mobile">Mobile</Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name="layout"
                              style={{ width: 200 }}
                              rules={[{ required: true, message: 'Please select a layout' }]}
                            >
                              <Select placeholder="Select Layout" size="large">
                                <Option value="201">201</Option>
                                <Option value="202">202</Option>
                                <Option value="211">211</Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name="platform"
                              style={{ width: 200 }}
                              rules={[{ required: true, message: 'Please select a platform' }]}
                            >
                              <Select placeholder="Social Feed Platform" size="large">
                                <Option value="twitter">Twitter (X)</Option>
                                <Option value="instagram">Instagram</Option>
                                <Option value="tiktok">TikTok</Option>
                                <Option value="youtube_shorts">YouTube Shorts</Option>
                                <Option value="bluesky">Bluesky</Option>
                                <Option value="linkedin_profile">Linkedin Profile</Option>
                                <Option value="linkedin_company">Linkedin Company</Option>
                                <Option value="pinterest_user_pins">Pinterest User Pins</Option>
                                <Option value="pinterest_board_pins">Pinterest Board Pins</Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name="socialFeedAccount"
                              style={{ width: 250 }}
                              rules={[{ required: true, message: 'Please enter a social feed account' }]}
                            >
                              <Input placeholder="Social Feed Account" size="large" prefix={'@'} />
                            </Form.Item>

                            <Form.Item
                              name="firstPost"
                              style={{ width: 500 }}
                              rules={[{ required: false }]}
                            >
                              <Input placeholder="First Post URL (Optional)" size="large" 
                                    suffix={
                                      <Tooltip title={title} overlayInnerStyle={{ width: 450 }}>
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    } 
                              />
                            </Form.Item>

                            <Form.Item>
                              <Button type="primary" htmlType="submit" size="large">
                                Create
                              </Button>
                            </Form.Item>
                          </Form>;

    const fileUploadTab = <Form
                            form={formFile}
                            layout="vertical"
                            onFinish={onFinishFile}
                          >
                            <Form.Item
                              name="htmlFile"
                              valuePropName="fileList"
                              getValueFromEvent={(e) => e?.fileList}
                              rules={[{ required: true, message: 'Please upload an HTML file' }]}
                            >
                              <Upload accept=".html" beforeUpload={() => false} maxCount={1}>
                                <Button icon={<UploadOutlined />} size="large">
                                  Upload an HTML file
                                </Button>
                              </Upload>
                            </Form.Item>

                            <Form.Item
                              name="device"
                              style={{ width: 200 }}
                              rules={[{ required: true, message: 'Please select a device type' }]}
                            >
                              <Select placeholder="Select Device" size="large">
                                <Option value="desktop">Desktop</Option>
                                <Option value="mobile">Mobile</Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name="layout"
                              style={{ width: 200 }}
                              rules={[{ required: true, message: 'Please select a layout' }]}
                            >
                              <Select placeholder="Select Layout" size="large">
                                <Option value="201">201</Option>
                                <Option value="202">202</Option>
                                <Option value="211">211</Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name="platform"
                              style={{ width: 200 }}
                              rules={[{ required: true, message: 'Please select a platform' }]}
                            >
                              <Select placeholder="Social Feed Platform" size="large">
                                <Option value="twitter">Twitter (X)</Option>
                                <Option value="instagram">Instagram</Option>
                                <Option value="tiktok">TikTok</Option>
                                <Option value="youtube_shorts">YouTube Shorts</Option>
                                <Option value="bluesky">Bluesky</Option>
                                <Option value="linkedin_profile">Linkedin Profile</Option>
                                <Option value="linkedin_company">Linkedin Company</Option>
                                <Option value="pinterest_user_pins">Pinterest User Pins</Option>
                                <Option value="pinterest_board_pins">Pinterest Board Pins</Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name="socialFeedAccount"
                              style={{ width: 250 }}
                              rules={[{ required: true, message: 'Please enter a social feed account' }]}
                            >
                              <Input placeholder="Social Feed Account" size="large" prefix={'@'} />
                            </Form.Item>

                            <Form.Item
                              name="firstPost"
                              style={{ width: 500 }}
                              rules={[{ required: false }]}
                            >
                              <Input placeholder="First Post URL (Optional)" size="large" 
                                    suffix={
                                      <Tooltip title={title} overlayInnerStyle={{ width: 450 }}>
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    } 
                              />
                            </Form.Item>

                            <Form.Item>
                              <Button type="primary" htmlType="submit" size="large">
                                Create
                              </Button>
                            </Form.Item>

                            <Form.Item>
                              Save a complete web page as a single HTML file using Chrome Extension: <Button type='link' href="https://chromewebstore.google.com/detail/save-page-we/dhhpefjklgkmgeafimnjhojgjamoafof" target='_blank' rel="noopener noreferrer">Save Page WE</Button>
                            </Form.Item>
                        </Form>;

      return (
        <div style={{ width: 800, margin: 'auto' }}>
          <Title level={1}>Demo Creator</Title>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: 'Insert URL',
                children: insertUrlTab,
              },
              {
                key: '2',
                label: 'File Upload',
                children: fileUploadTab,
              },
            ]}
          />
        </div>
      );
  }
};

export default DemoCreator;
