import Modal from "../components/Modal/Modal";
import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  notification,
} from "antd";
import PublisherSelect from "../pages/DomainSelection/PublisherSelect";
import DomainSelect from "../pages/DomainSelection/DomainSelect";
import Select from "../components/Select/Select";
import TextArea from "antd/es/input/TextArea";
import Button from "../components/Button";
import { useEffect, useState } from "react";
import { Advertiser, Mode, Provider, adTypes } from "../utils/types";
import PureCard from "../components/PureCard/PureCard";
import {
  adTypeOptions,
  defaultTimer,
  getUuidv4,
  getallDomainesUrl,
  providersOptions,
  unitSizeOptions,
  validateField,
} from "../utils/utils";
import { MinusOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { NotificationType } from "../utils/notifictions";

interface AdvertisersModalProps {
  mode: Mode;
  layoutsList: any[];
  close: () => void;
  onFinish: (tag: any) => void;
  data: any;
  tags: string[];
}

const EditAdvertisersModal = (props: AdvertisersModalProps) => {
  const { mode, close, data, onFinish, tags } = props;
  const [advertiser, setAdvertiser] = useState<Advertiser>({} as Advertiser);
  const [adTimerDisable, setAdTimerDisable] = useState<boolean>(false);
  const [refreshDisable, setRefreshDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    let provider;
    if(data.tagType !== "PREBID") {
      provider = [];
      data.providers = [];
    } else {
      if(data?.providers){
        provider = JSON.parse(data.providers);
        if(typeof provider === "string") provider = JSON.parse(provider);
      }
    }

    setAdvertiser({
      ...data,
      providers: provider,
      location:
        !data.location || data.location === ""
          ? []
          : data.location.split(",").map(Number),
    });
  }, [data]);

  const getProvidersOptions = () => {
    let selectedProviders = advertiser.providers.map(
      (provider) => provider.providerName
    );
    return providersOptions
      .filter((provider) => !selectedProviders.includes(provider.providerName))
      .map((provider) => provider.providerName)
      .map((provider: string) => {
        return { value: provider, label: provider };
      });
  };

  const getProviderUnit = (provider: Provider, index: number) => {
    let fields: string[] = [];
    let res: any[] = [];

    switch (provider.providerName) {
      case "33across":
        fields = ["siteId", "productId"];
        break;
      case "eplanning":
        fields = ["ci"];
        break;
      case "smartadserver":
        fields = ["networkId", "siteId", "pageId", "formatId"];
        break;
      case "rubicon":
        fields = ["accountId", "siteId", "zoneId"];
        break;
      case "openx":
        fields = ["delDomain", "unit"];
        break;
      case "pubmatic":
        fields = ["publisherId"];
        break;
      case "rise":
        fields = ["org"];
        break;
      case "sovrn":
        fields = ["tagid"];
        break;
      case "appnexus":
        fields = ["placementId"];
        break;
      case "nextMillennium":
        fields = ["placement_id"];
        break;
      case "minutemedia":
        fields = ["org"];
        break;
      case "onetag":
        fields = ["pubId"];
        break;         
      default:
        fields = [""];
        break;
    }

    res.push(
      <Button
        size="small"
        style={{
          marginTop: "auto",
          marginBottom: "auto",
          borderRadius: "50%",
        }}
        type="primary"
        icon={<MinusOutlined />}
        onClick={() => {
          let providers = [...advertiser.providers];
          providers.splice(index, 1);
          setAdvertiser({
            ...advertiser,
            providers,
          });
        }}
      ></Button>
    );

    res.push(
      <Form.Item
        label="Choose Provider"
        name={`provider_${provider.id}`}
        rules={[
          {
            validator: () => validateField({}, provider.providerName),
            message: `Please select provider`,
          },
        ]}
      >
        <Select
          style={{ width: "230px" }}
          allowClear
          placeholder="Please select provider"
          options={getProvidersOptions()}
          onChange={(value: any) => {
            let providers = [...advertiser.providers];
            let provider = providers[index];
            provider.providerName = value;
            providers[index] = provider;

            setAdvertiser((prevState) => ({
              ...prevState,
              providers: providers,
            }));
          }}
          defaultValue={provider.providerName || undefined}
          disabledVirtual
        />
      </Form.Item>
    );
    for (let field of fields) {
      let val = advertiser.providers[index].providerFields[field];

      field !== "" &&
        res.push(
          <Form.Item
            label={`Type ${ field }`}
            name={`${field}_${provider.id}`}
            rules={[
              {
                validator: () =>
                  validateField(
                    {},
                    val
                  ),
                message: `Please type ${field}`,
              },
            ]}
          >
            <Input
              placeholder={`Please Type ${field}`}
              size="large"
              onChange={(e) => {
                let value = e.target.value;
                let providers = [...advertiser.providers];
                let provider = providers[index];
                provider.providerFields[field] = value;
                setAdvertiser((prevState) => ({
                  ...prevState,
                  providers: providers,
                }));
              }}
              defaultValue={val}
              value={val}
            />
          </Form.Item>
        );
    }

    return res;
  };
  const isAllProvidersSelected = () => {
    if (advertiser.providers) {
      let selectedProviders = advertiser.providers.map(
        (provider) => provider.providerName
      );
      return (
        providersOptions.filter(
          (provider) => !selectedProviders.includes(provider.providerName)
        ).length === 0
      );
    } else return false;
  };

  let checkPubIdRelatedToDomain = async () => {
    let data = await getallDomainesUrl(advertiser.pub_id);
    let domains = data.map((item) => item.value);
    if (!domains.includes(advertiser.domain_id)) return false;
    return true;
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };

  return Object.keys(advertiser).length > 0 ? (
    <Modal
      width={"60%"}
      title={<h2>{mode === "edit" ? "Edit tag" : "Duplicate Tag"}</h2>}
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
    >
      {advertiser && (
        <Form
          name="basic"
          onFinish={async (tag: any) => {
            setLoading(true);
            if (await checkPubIdRelatedToDomain()) {
              onFinish({
                ...advertiser,
                location: advertiser.location.toString(),
              });
            } else {
              openNotificationWithIcon(
                "warning",
                "The domain is not related to the publisher"
              );
            }
            setLoading(false);
          }}
          onFinishFailed={() => {}}
          autoComplete="off"
          layout="vertical"
          requiredMark
          initialValues={advertiser}
        >
          <Row
            style={{
              width: "100%",
              background: "",
              justifyContent: "space-between",
            }}
          >
            <Form.Item
              label="Choose Publisher"
              style={{ width: "31%" }}
              rules={[
                {
                  required: !advertiser.pub_id,
                  message: "Please select publisher",
                },
              ]}
            >
              <PublisherSelect
                defaultValue={advertiser.publisher}
                mode={mode}
                onSelect={(pub_id, pub_key) => {
                  setAdvertiser({
                    ...advertiser,
                    pub_id: pub_id,
                    pub_key: pub_key,
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              label="Choose Domain"
              style={{ width: "31%" }}
              // name="domain"
              rules={[
                {
                  required: !advertiser.domain_id,
                  message: "Please select domain",
                },
              ]}
            >
              <DomainSelect
                defaultValue={advertiser.domain}
                mode={mode}
                pub_id={advertiser.pub_id}
                onSelect={(value: any) => {
                  setAdvertiser({
                    ...advertiser,
                    domain_id: value,
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              name="platform"
              label="Platform"
              style={{ width: "31%" }}
            >
              <Select
                placeholder="Select Platform Type"
                options={[
                  { value: "", label: "All" },
                  { value: "desktop", label: "Desktop" },
                  { value: "mobile", label: "Mobile" }
                ]}
                onChange={(value: any) => {
                    setAdvertiser({
                      ...advertiser,
                      platform: value
                    });
                }}
              />
            </Form.Item>

          </Row>

          <Row
            style={{
              width: "100%",
              background: "",
              justifyContent: "space-between",
            }}
          >
            <Form.Item
              label="Choose WID"
              style={{ width: "31%" }}
              name="wid"
              rules={[
                { required: true, message: "Please select WID" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue("wid") < 0) {
                      return Promise.reject("Please select number > 0");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                placeholder="Please select WID"
                size="large"
                style={{ width: "100%" }}
                type="number"
                onChange={(value: any) => {
                  setAdvertiser({
                    ...advertiser,
                    wid: value,
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              name="tagType"
              label="Choose Tag Type"
              rules={[{ required: true, message: "Please select tag type" }]}
              style={{ width: "31%" }}
            >
              <Select
                allowClear
                placeholder="Select Tag Type"
                options={tags.map((tag: any) => ({ value: tag, label: tag }))}
                onChange={(value: any) => {
                  if (value === "PREBID") {
                    setAdvertiser({
                      ...advertiser,
                      tagType: value,
                    });
                  } else {
                    setAdvertiser({
                      ...advertiser,
                      tagType: value,
                      providers: [],
                    });
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              label="Choose Size"
              style={{ width: "31%" }}
              name="size"
              rules={[{ required: true, message: "Please select size" }]}
            >
              <Select
                options={unitSizeOptions.map((size: string) => {
                  return { value: size, label: size };
                })}
                onChange={(value: any) =>
                  setAdvertiser({
                    ...advertiser,
                    size: value,
                  })
                }
              />
            </Form.Item>
          </Row>

          <Row
            style={{
              width: "100%",
              background: "",
              justifyContent: "space-between",
            }}
          >
            <Form.Item
              label="Type Placement"
              style={{ width: "31%" }}
              name="placement"
              rules={[
                {
                  validator: validateField,
                  message: "Please type Placement",
                },
              ]}
            >
              <TextArea
                size="large"
                style={{ height: 100 }}
                onChange={(e) =>
                  setAdvertiser({
                    ...advertiser,
                    placement: e.target.value,
                  })
                }
              />
            </Form.Item>

            <Form.Item
                label="Type Backfill Script"
                name="tagBackfill"
                style={{ width: "31%" }}
              >
                <TextArea
                  placeholder="Paste your backfill script here"
                  style={{
                    display: "flex",
                    height: 100,
                  }}
                  value={advertiser.tagBackfill}
                  onChange={(e) =>
                    setAdvertiser({
                      ...advertiser,
                      tagBackfill: e.target.value,
                    })
                  }
                />
            </Form.Item>

            {advertiser.tagType === "PREBID" && (
            <>
              <Form.Item
                label="Timeout"
                name="timeout"
                style={{ width: "14%" }}
                rules={[
                  { required: false, message: "Please type Timeout" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("timeout") < 0) {
                        return Promise.reject("Please type number > 0");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  name="timeout"
                  placeholder="800"
                  size="large"
                  style={{ width: "100%" }}
                  type="number"
                  value={advertiser.timeout}
                  onChange={(value: any) => {
                    setAdvertiser({
                      ...advertiser,
                      timeout: value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item                 
                label="Floor Price"
                name="bidfloor"
                style={{ width: "14%" }}
                rules={[
                  { required: false, message: "Please type Bid Floor" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("bidfloor") < 0) {
                        return Promise.reject("Please type number > 0");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  name="bidfloor"
                  placeholder="0.05"
                  size="large"
                  style={{ width: "100%" }}
                  type="number"
                  value={advertiser.bidfloor}
                  onChange={(value: any) => {
                    setAdvertiser({
                      ...advertiser,
                      bidfloor: value,
                    });
                  }}
                />

              </Form.Item>
            </>
            )}

            {advertiser.tagType !== "PREBID" && (
              <Form.Item
                label="Type Script"
                name="tagScript"
                style={{ width: "31%" }}
                rules={[{ required: true, message: "Please type script" }]}
                required
              >
                <TextArea
                  placeholder="Paste your script here"
                  style={{
                    display: "flex",
                    height: 100,
                  }}
                  value={advertiser.tagScript}
                  onChange={(e) =>
                    setAdvertiser({
                      ...advertiser,
                      tagScript: e.target.value,
                    })
                  }
                />
              </Form.Item>
            )}
          </Row>

          {advertiser.tagType === "PREBID" && (
            <PureCard>
              <Row
                style={{
                  overflow: "scroll",
                  maxHeight: "250px",
                }}
              >
                <Row
                  justify="end"
                  style={{
                    width: "100%",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <Button
                    disabled={isAllProvidersSelected()}
                    icon={<PlusCircleOutlined />}
                    style={{ marginBottom: "16px", alignSelf: "end" }}
                    title="Add Provider"
                    type="primary"
                    onClick={() => {
                      let providers = advertiser.providers || [];
                      providers.push({
                        providerName: null,
                        providerFields: {},
                        id: getUuidv4(),
                      });

                      setAdvertiser((prevState) => ({
                        ...prevState,
                        providers: providers,
                      }));
                    }}
                  />
                </Row>
                {advertiser.providers?.map(
                  (provider: Provider, index: number) => {
                    return (
                      <Row
                        style={{
                          width: "100%",
                        }}
                        key={index}
                      >
                        <Space size={"large"}>
                          {getProviderUnit(provider, index)}
                        </Space>
                      </Row>
                    );
                  }
                )}
              </Row>
            </PureCard>
          )}
          <>
            <Row justify="center">
              <Form.Item
                label="Ad type"
                // name="adType"

                rules={[{ required: true, message: "Please select Ad type" }]}
                style={{ width: "100%" }}
              >
                <Select
                  allowClear
                  placeholder="Please select Ad type"
                  disabled
                  options={adTypeOptions.map((item: any) => {
                    return { value: item.value, label: item.label };
                  })}
                  value={advertiser.adType ? adTypes[advertiser.adType] : ""}
                />
              </Form.Item>
            </Row>
            <Row
              justify="space-between"
              style={{ width: "100%", background: "", gap: "16px" }}
            >
              <Checkbox
                checked={adTimerDisable}
                onChange={(e) => {
                  setAdTimerDisable(e.target.checked);
                  setAdvertiser({
                    ...advertiser,
                    adTimer: e.target.checked
                      ? defaultTimer
                      : advertiser.adTimer,
                  });
                }}
              >
                Disabled
              </Checkbox>

              <Form.Item
                label="Choose Ad Timer (in seconds)"
                name="adTimer"
                style={{ width: "25%" }}
                rules={[{ required: true, message: "Please select ad timer" }]}
              >
                <InputNumber
                  hidden={adTimerDisable}
                  style={{ width: "100%" }}
                  // value={advertiser.adTimer}
                  placeholder="Please select Ad timer"
                  size="large"
                  type="number"
                  onChange={(value: any) =>
                    setAdvertiser({
                      ...advertiser,
                      adTimer: value,
                    })
                  }
                />
              </Form.Item>

              <Checkbox
                checked={refreshDisable}
                onChange={(e) => {
                  setRefreshDisable(e.target.checked);
                  setAdvertiser({
                    ...advertiser,
                    refresh: e.target.checked
                      ? defaultTimer
                      : advertiser.refresh,
                  });
                }}
              >
                Disabled
              </Checkbox>

              <Form.Item
                label="Choose Refresh (in seconds)"
                name="refresh"
                rules={[
                  {
                    required: true,
                    message: "Please select refresh",
                  },
                ]}
                required
                style={{ width: "25%" }}
              >
                <InputNumber
                  hidden={refreshDisable}
                  style={{ width: "100%" }}
                  value={advertiser.refresh}
                  placeholder="Please select refresh"
                  size="large"
                  type="number"
                  onChange={(value) =>
                    setAdvertiser({
                      ...advertiser,
                      refresh: value,
                    })
                  }
                />
              </Form.Item>

              {advertiser.adType === "firstImpression" && (
                <Form.Item
                  label="Choose if Skip"
                  name="skip"
                  rules={[{ required: true, message: "Please select if Skip" }]}
                  style={{ width: "31%" }}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select if Skip"
                    options={[
                      { value: 0, label: "No" },
                      { value: 1, label: "Yes" },
                    ].map((item: any) => {
                      return { value: item.value, label: item.label };
                    })}
                    value={advertiser.skip ? "Yes" : "No"}
                    onChange={(value) =>
                      setAdvertiser({
                        ...advertiser,
                        skip: value,
                      })
                    }
                  />
                </Form.Item>
              )}
              {advertiser.adType && advertiser.adType.includes("inContent") && (
                <Form.Item
                  label="Choose Location"
                  name="location"
                  rules={[
                    { required: true, message: "Please select Location" },
                  ]}
                  style={{ width: "31%" }}
                >
                  <Select
                    value={advertiser.location}
                    allowClear
                    mode="multiple"
                    placeholder="Please select Location"
                    options={Array.from(
                      { length: 20 },
                      (_, index) => index + 1
                    ).map((value: number) => {
                      return { value: value, label: value };
                    })}
                    onChange={(value) => {
                      setAdvertiser({
                        ...advertiser,
                        location: value,
                      });
                    }}
                  />
                </Form.Item>
              )}


              {advertiser?.adType && advertiser.adType === "outOfFrame" && (
                <Form.Item label="Sticky">
                  <Checkbox
                    checked={advertiser.sticky}
                    onChange={(e) => {
                      setAdvertiser({
                        ...advertiser,
                        sticky: e.target.checked
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Row>
          </>

          <Row style={{ justifyContent: "center", gap: "16px" }}>
            <Button type="default" title="Cancel" onClick={close}></Button>
            <Button
              loading={loading}
              type="primary"
              title={mode === "edit" ? "Save Changes" : "Duplicate"}
              onClick={() => {}}
              htmlType="submit"
            ></Button>
          </Row>
        </Form>
      )}
      {contextHolder}
    </Modal>
  ) : null;
};

export default EditAdvertisersModal;
