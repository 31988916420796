import client from "../client";
import { SocialFeedMapping  } from "../../utils/types";

export const deleteSocialFeedMapping = async (selectedItem: any[]) => {
  const res = await client.delete(`social-feed-mapping/delete_social_feed_mapping`, {
    data: selectedItem,
  });
  return res.data;
};

export const setSocialFeedMapping  = async (data: SocialFeedMapping ) => {
  const res = await client.post(`social-feed-mapping/create_social_feed_mapping`, data);
  return res.data;
};

export const updateSocialFeedMapping  = async (data: SocialFeedMapping ) => {
  const res = await client.post(`social-feed-mapping/update_social_feed_mapping`, data);
  return res.data;
};

export const getAllSocialFeedMapping  = async () => {
  const res = await client.get(`social-feed-mapping/get_all_social_feed_mapping`);
  return res.data;
};