import Modal from "../components/Modal/Modal";
import { Col, Form, Input, Image, Row, Table, Checkbox } from "antd";
import PublisherSelect from "../pages/DomainSelection/PublisherSelect";
import DomainSelect from "../pages/DomainSelection/DomainSelect";
import Select from "../components/Select/Select";
import Button from "../components/Button";
import { useState, useEffect } from "react";
import { Mode } from "../utils/types";
import { Scheduler } from "../utils/types";
import { IDS } from "../utils/elementsIds";
import { getSchedulerUrls } from "../api/services/Unit";
import { DeleteIcon } from "../assets/images/delete";
interface NewSchedulerModalProps {
  close: () => void;
  onFinish: (scheduler: any) => void;
  mode: Mode;
  data?: Scheduler;
}

interface DataType {
  id: number;
  social_account: string;
  social_type: string;
  publisher_domain_url: string;
  enabled: boolean;
  all_domain_pages: boolean;
}

type SchedulerUrl = {
  id: number;
  social_account: string;
  social_type: string;
  publisher_domain_url: string;
  enabled: boolean;
  all_domain_pages: boolean;
};


const MyCheckBox = (props: any) => {
  const { checked, save, name } = props;
  const [box, setBox] = useState<boolean>(checked); 
  return <Checkbox name={name} checked={box} onChange={(e) => { setBox(e.target.checked); save(e); }}></Checkbox>
}

const MySelectBox = (props: any) => {
  const { social, save } = props,
        [socialType, setSocialType] = useState<string>(social);

  return <Select
            placeholder="Select platform"
            onChange={(e: any) => {
              setSocialType(e);
              save(e, { social_type: e || '' });
            }}
            defaultValue={socialType}
            options={[
              { value: 'instagram', label: 'Instagram' },
              { value: 'tiktok', label: 'Tiktok' },
              { value: 'twitter', label: 'Twitter' },
              { value: 'twitter_legacy', label: 'Twitter Legacy' },
              { value: 'youtube_shorts', label: 'YouTube Shorts' },
              { value: 'bluesky', label: 'Bluesky' },
              { value: 'linkedin_profile', label: 'Linkedin Profile' },
              { value: 'linkedin_company', label: 'Linkedin Company' },
              { value: 'pinterest_user_pins', label: 'Pinterest User Pins' },
              { value: 'pinterest_board_pins', label: 'Pinterest Board Pins' }
            ]}
        ></Select>; 
}

const EditableCell: React.FC<{
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof DataType;
  record: DataType;
  handleSave: (record: DataType, values: any) => void;
}> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [form] = Form.useForm();
  
  const save = async (e: any, v: any = null) => {
    try {
      let values = await form.validateFields();
      if(v !== null) {
        handleSave(record, v);
      } 
      else if(e.target?.type === 'checkbox') {
        let obj: Record<string, any> = {};
        obj[e.target.name] = e.target.checked;
        handleSave(record, obj);
      } 
      else {
        handleSave(record, values);
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  
  let childNode = children;
  if (editable) {
    const def = JSON.stringify(children),
          arr = JSON.parse(def),
          initialValues = {
            [dataIndex]: (dataIndex === "enabled" || dataIndex === "all_domain_pages") ? arr[1] || false : arr[1] || ''
          };

    childNode = <Form form={form} component={false} initialValues={initialValues}>
        <Form.Item name={dataIndex} style={{ margin: 0, display: (dataIndex === "enabled" || dataIndex === "all_domain_pages") ? "flex" : "block", justifyContent: "center" }}>
          { (dataIndex === "social_account" || dataIndex === "publisher_domain_url") && <Input onBlur={save} /> }
          { (dataIndex === "social_type") && <MySelectBox social={[initialValues.social_type]} save={save} /> }
          { (dataIndex === "enabled") && <MyCheckBox name="enabled" checked={initialValues.enabled} save={save} /> }
          { (dataIndex === "all_domain_pages") && <MyCheckBox name="all_domain_pages" checked={initialValues.all_domain_pages} save={save} /> }
        </Form.Item>
      </Form>;
  }

  return <td {...restProps}>{childNode}</td>;
};

const NewSchedulerModal = (props: NewSchedulerModalProps) => {
  const { close, onFinish, mode, data } = props;
  const [scheduler, setScheduler] = useState<Scheduler>(data ? {...data, platforms: data.platform } : {} as Scheduler);
  const [schedulerUrls, setSchedulerUrls] = useState<SchedulerUrl[]>([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const platformOptions = [
    {
      key: 1,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          Instagram
        </div>
      ),
      value: "instagram",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platforms.includes("instagram"),
    },
    {
      key: 2,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          Tiktok
        </div>
      ),
      value: "tiktok",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platforms?.includes("tiktok"),
    },
    {
      key: 3,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          Twitter
        </div>
      ),
      value: "twitter",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platforms?.includes("twitter_legacy"),
    },
    {
      key: 4,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          Twitter Legacy
        </div>
      ),
      value: "twitter_legacy",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platform?.includes("twitter_legacy"),
    },
    {
      key: 5,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          Youtube Shorts
        </div>
      ),
      value: "youtube_shorts",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platforms.includes("youtube_shorts"),
    },
    {
      key: 6,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          Bluesky
        </div>
      ),
      value: "bluesky",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platforms.includes("bluesky"),
    },
    {
      key: 7,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          Linkedin Profile
        </div>
      ),
      value: "linkedin_profile",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platforms.includes("linkedin_profile"),
    },
    {
      key: 8,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          Linkedin Company
        </div>
      ),
      value: "linkedin_company",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platforms.includes("linkedin_company"),
    },
    {
      key: 9,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          Pinterest User Pins
        </div>
      ),
      value: "pinterest_user_pins",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platforms.includes("pinterest_user_pins"),
    },
    {
      key: 10,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          Pinterest Board Pins
        </div>
      ),
      value: "pinterest_board_pins",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platforms.includes("pinterest_board_pins"),
    },
  ],
  initialValues = {
    username: scheduler.username ?? "",
    platforms: scheduler.platforms ? [scheduler.platforms] : [],
    rate: scheduler.rate ?? "",
    widget_id: scheduler.widget_id ?? null
  };
  
  const handleSave = (row: any, val: any) => {
    const s = schedulerUrls.map((item, index) => {
      if(row === index) {
        item = { ...item, ...val};
      }
      return item;
    });

    setSchedulerUrls(s);
  }

  const getColumns = () => {
    return [
      {
        title: "Type Username",
        key: "id",
        dataIndex: "social_account",
        width: "29%",
        editable: true
      },
      {
        title: "Select Platform",
        dataIndex: "social_type",
        width: "29%",
        editable: true
      },
      {
        title: "Domain URL",
        dataIndex: "publisher_domain_url",
        width: "29%",
        editable: true
      },
      {
        title: "Enabled",
        dataIndex: "enabled",
        width: "1%",
        editable: true
      },
      {
        title: "All Pages",
        dataIndex: "all_domain_pages",
        width: "9%",
        editable: true
      },
      {
        title: "",
        width: "1%",
        render: (_: any, record: any) => (
          <Button
            permission="delete:scheduler"
            type="default"
            onClick={() => {
              const deleteRow = schedulerUrls.filter(x => x.id !== record.id);
              setSchedulerUrls(deleteRow);
            }}
            icon={
              <span className="icon">
                <DeleteIcon color={"#262626"} />
              </span>
            }
          ></Button>
        )
      }
    ];
  };

  const mergedColumns = getColumns().map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (_: any,record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const addNewSchedulerUrl = () => {
    setSchedulerUrls((prev) => [
      ...prev,
      {
        id: Math.floor(10000 + Math.random() * 90000),
        social_account: "",
        social_type: "",
        publisher_domain_url: "",
        enabled: false,
        all_domain_pages: false,
      },
    ]);
  };

  useEffect(() => {
    const getSchedulerUrlsByWID = async () => {
      const urlsList = await getSchedulerUrls(scheduler);
      setLoading(false);
      setSchedulerUrls(urlsList);
    }

    if(mode !== "new") getSchedulerUrlsByWID();
  },[]);

  let final = {};
  if(mode === "new") final = scheduler; 
  else final = { ...scheduler, schedulerUrls: schedulerUrls };

  return (
    <Modal
      width={"80%"}
      title={<h2>{mode === "new" ? "Add New Scheduler" : "Edit Scheduler"}</h2>}
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
    >
      <Form name="basic" autoComplete="off" layout="vertical" requiredMark onFinish={() => onFinish(final)} initialValues={initialValues}>
        <Row gutter={[24, 24]}>

          {mode === "new" && (<>
          <Col span={8}>
            <Form.Item
              label="Choose Publisher"
              name="publisher"
              rules={[
                {
                  required: !scheduler.pub_id,
                  message: "Please select publisher",
                },
              ]}
            >
              <PublisherSelect
                id={IDS.NEW_TAG.PUBLISHER_SELECT}
                mode="new"
                onSelect={(pub_id) => {
                  setScheduler({
                    ...scheduler,
                    pub_id: pub_id,
                  });
                }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Choose Domain"
              name="domain"
              rules={[
                {
                  required: !scheduler.domain_id,
                  message: "Please select domain",
                },
              ]}
            >
              <DomainSelect
                id={IDS.NEW_TAG.DOMAIN_SELECT}
                onSelect={(value: any) => {
                  setScheduler({
                    ...scheduler,
                    domain_id: value,
                  });
                }}
              />
            </Form.Item>
          </Col>
          </>)}
          <Col span={8}>
            <Form.Item
              label="Type Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please type username",
                },
              ]}
              normalize={(value) => {
                return value.startsWith("@") || value === ""
                  ? value
                  : `@${value}`;
              }}
            >
              <Input
                style={{ height: "40px" }}
                placeholder="Type @username"
                value={scheduler.username}
                onChange={(e) => {
                  if (e.target.value.includes("@")) {
                    setScheduler({
                      ...scheduler,
                      username: e.target.value,
                    });
                  } else {
                    if (e.target.value !== "")
                      setScheduler({
                        ...scheduler,
                        username: "@" + e.target.value,
                      });
                    else
                      setScheduler({
                        ...scheduler,
                        username: "",
                      });
                  }
                }}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Type Widget Id"
              name="widget_id"
            >
              <Input
                style={{ height: "40px" }}
                placeholder="Type Widget Id"
                value={scheduler.widget_id ?? undefined}
                onChange={(e) => {
                  if (e.target.value !== "")
                    setScheduler({
                      ...scheduler,
                      widget_id: Number(e.target.value),
                    });
                  else
                    setScheduler({
                      ...scheduler,
                      widget_id: null,
                    });
                }}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Select Platform"
              name="platforms"
              rules={[
                {
                  required: true,
                  message: "Please select platform",
                },
              ]}
            >
              <Select
                placeholder="Select platform"
                mode="tags"
                onChange={(value: string[]) => {
                  setScheduler({
                    ...scheduler,
                    platforms: [...value],
                  });
                }}
                value={scheduler.platforms}
                options={scheduler.platforms?.length > 0 ? [] : platformOptions}
              ></Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Select Rate"
              name="rate"
              rules={[
                {
                  required: true,
                  message: "Please select rate",
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Select Rate"
                options={[
                  { value: "daily", label: "Daily" },
                  { value: "weekly", label: "Weekly" },
                  { value: "monthly", label: "Monthly" },
                ].map((item: any) => {
                  return { value: item.value, label: item.label };
                })}
                onChange={(value) =>
                  setScheduler({
                    ...scheduler,
                    rate: value,
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
        <Col span={24}>
            {schedulerUrls.length > 0 && (
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                columns={mergedColumns}
                dataSource={schedulerUrls}
                rowKey={"id"}
                pagination={false}
                loading={!!loading}
              />
            )}
            {mode !== "new" && loading && schedulerUrls.length === 0 && (
              <Table
                rowKey={"id"}
                pagination={false}
                loading={!!loading}
              />
            )}
            <br />
          </Col>
        </Row>

        <Row justify="center">
          <Button
            type="primary"
            title={mode === "new" ? "Add Scheduler" : "Edit Scheduler"}
            htmlType="submit"
          ></Button>

          { mode !== "new" &&           
            <Button
              type="default"
              title="Add Scheduler For Category or Domain URL"
              htmlType="button"
              onClick={addNewSchedulerUrl}
              style={{ margin: "0 0 0 20px" }}
            ></Button>  
          }

        </Row>
      </Form>
    </Modal>
  );
};

export default NewSchedulerModal;
