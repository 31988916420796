import { useEffect, useMemo, useState } from "react";
import { Col, ColorPicker, Space } from "antd";
import type { ColorPickerProps } from "antd/es/color-picker";
import { Form, Row } from "antd";

const ColorPickerWidget = (props: any) => {
  const { ColorLabel, ColorName, keyColor, colorRgb, setColorRgb, widget, setWidget } = props;

  const [formatRgb, setFormatRgb] = useState<ColorPickerProps["format"]>("rgb");
  const rgbString = useMemo(
    () => (typeof colorRgb === "string" ? colorRgb : colorRgb.toRgbString()),
    [colorRgb]
  );

  useEffect(() => {
    setColorRgb(rgbString);
  }, [colorRgb, rgbString, setColorRgb]);

  return (
    <Form.Item
      label={`${ColorLabel}`}
      name={`${keyColor}`}
      key={`${keyColor}`}
      style={{ width: "24%" }}
    >
      <Row align="middle">
        <Space>
          <Col>
            <ColorPicker
              format={formatRgb}
              value={colorRgb}
              onChange={(value, css) => {
                setColorRgb(css);
                switch (ColorName) {
                  case "InContentTitleColor":
                    setWidget({
                      ...widget,
                      InContentTitleColor: css,
                    });
                    break;
                  case "InContentTitleBackgroundColor":
                    setWidget({
                      ...widget,
                      InContentTitleBackgroundColor: css,
                    });
                    break;
                  case "InContentUnitBackgroundColor":
                    setWidget({
                      ...widget,
                      InContentUnitBackgroundColor: css,
                    });
                 break;
                    case "InContentUnitFrameColor":
                      setWidget({
                        ...widget,
                        InContentUnitFrameColor: css,
                      });
                      break;
                  default:
                    break;
                }
              }}
              onFormatChange={setFormatRgb}
            />
          </Col>
          <Col>
            <span style={{ fontWeight: 200 }}>{rgbString}</span>
          </Col>
        </Space>
      </Row>
    </Form.Item>
  );
};

export default ColorPickerWidget;
