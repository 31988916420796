import React, { useEffect, useState } from "react";
import {
  Row,
  Table,
  Tooltip,
  Popconfirm,
  Space,
  message,
  Tag,
  notification,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { getDomainsByPubId } from "../../api/services/Domains";
import {
  deleteWidget,
  updateWidgetBatch,
  insertWidgetBatch,
} from "../../api/services/Widgets";
import type { Color } from "antd/es/color-picker";
import PureCard from "../../components/PureCard/PureCard";
import Button from "../../components/Button";
import { DeleteIcon } from "../../assets/images/delete";
import { EditIcon } from "../../assets/images/edit";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { COLORS } from "../../utils/colors.js";
import NewDomainModal from "../../modals/NewDomainModal";
import { Domain } from "../../utils/types";
import { useAuth0 } from "@auth0/auth0-react";
import { NotificationType } from "../../utils/notifictions";
import { insertDomain, updateDomain } from "../../api/services/Domains";
import "./styles.scss";
import scriptText from "./scriptWID.json";
import { objectsEqual } from "../../utils/utils";
import CONFIG from "../../config.json";

interface DomainsModalProps {
  pub_id: number;
  pub_key: string;
}

const DomainsTable = (props: DomainsModalProps) => {
  const { pub_id, pub_key } = props;
  const [data, setData] = useState<Domain[]>([]);
  const [showNewDomain, setShowNewDomain] = useState<boolean>(false);
  const [showEditDomain, setShowEditDomain] = useState<boolean>(false);
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth0();
  const [api, contextHolder] = notification.useNotification();

  const [selectedRecord, setSelectedRecord] = useState<Domain>({} as Domain);
  let countSimilar = 0;
  useEffect(() => {
    getDomainsData();
  }, []);

  const generateDynamicScript = (
    widgets: number[],
    pub_key: string,
    domain_id: number
  ) => {
    let finalCopy = "";
    widgets.forEach((wid: number) => {
      let widget_id = wid.toString();
      let did = domain_id + 110000;
      let bucket =
        CONFIG.env === "DEV" ? "dev-units.carambo.la" : "units.carambo.la";

      const newScript = scriptText.scriptWID.carambolaNewScript
        .replace(/{___pub_key___}/g, pub_key)
        .replace(/{___domain_id___}/g, `${did}`)
        .replace(/{___wid___}/g, widget_id)
        .replace(/{___bucket___}/g, bucket);

      finalCopy += "\n\n" + newScript + "\n\n";
    });
    return finalCopy;
  };
  const handleDownload = (record: any) => {
    const scriptContent = generateDynamicScript(
      record.widgetsArray,
      record.public_key,
      record.domain_id
    );
    let scriptName = `${record.name}-carambola-script.txt`;
    downloadScript(scriptName, scriptContent);
    message.success("Script downloaded");
  };
  const downloadScript = (filename: string, content: string) => {
    const element = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const columns = [
    {
      title: "Domain Name",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "ID",
      dataIndex: "domain_id",
      width: "5%",
    },
    {
      title: "Domain URL",
      dataIndex: "url",
      width: "15%",
    },
    {
      title: "WID",
      dataIndex: "widgets",
      width: "20%",
      render: (_: any, record: Domain) => (
        <div style={{ width: "100%" }}>
          {record.widgetsArray?.map((wid: number) => {
            return (
              <Tag
                style={{
                  fontSize: "10px",
                  padding: "0px 12px",
                  border: "0px",
                }}
                color={isDarkMode ? "warning" : "blue"}
                key={wid}
              >
                {"WID " + wid}
              </Tag>
            );
          })}
        </div>
      ),
    },
    {
      title: (
        <div className="center-column-title">
          <span>Operation</span>
        </div>
      ),
      dataIndex: "operation",
      width: "15%",
      render: (_: any, record: any) => (
        <Row style={{ justifyContent: "center" }}>
          <Space>
            <Button
              type="default"
              onClick={() => handleDownload(record)}
              icon={
                <Tooltip
                  placement="topLeft"
                  title={"Download Script"}
                  overlayStyle={{ maxWidth: "400px" }}
                >
                  <span className="icon">
                    <DownloadOutlined
                      style={{
                        color: isDarkMode
                          ? COLORS.darkMode.main
                          : COLORS.lightMode.buttons,
                      }}
                    />
                  </span>
                </Tooltip>
              }
            ></Button>

            <Button
              permission="update:publishers"
              type="default"
              onClick={() => {
                setSelectedRecord(record);
                setShowEditDomain(true);
              }}
              icon={
                <Tooltip
                  placement="topLeft"
                  title={"Edit"}
                  overlayStyle={{ maxWidth: "400px" }}
                >
                  <span className="icon">
                    <EditIcon
                      color={
                        isDarkMode
                          ? COLORS.darkMode.main
                          : COLORS.lightMode.buttons
                      }
                    />
                  </span>
                </Tooltip>
              }
            ></Button>
          </Space>
        </Row>
      ),
    },
  ];

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };
  const getDomainsData = async () => {
    setLoading(true);
    let response = await getDomainsByPubId(pub_id);
    const newData = await Promise.all(
      response.map(async (domain: Domain) => {
        const widgetsArray = domain.widgets?.split(",").map((wid: string) => {
          return parseInt(wid);
        });
        return {
          ...domain,
          widgetsArray,
        };
      })
    );
    setData(newData);
    setLoading(false);
  };

  const close = () => {
    setShowNewDomain(false);
    setShowEditDomain(false);
  };

  const isIdenticalPlatforms = (obj: any) => {
    obj["is_in_content"] =
      obj["is_mobile"] || obj["is_tablet"] || obj["is_in_content"];
    obj["is_mobile"] = obj["is_mobile"] || obj["is_tablet"];
    return obj;
  };

  const areWidgetObjectsSimilar = (obj1: any, obj2: any): boolean => {
    isIdenticalPlatforms(obj1);
    isIdenticalPlatforms(obj2);

    const propsToCompare = [
      "widget_id",
      "is_in_content",
      "is_mobile",
      "is_tablet",
      "item_type",
    ];

    return propsToCompare.every((prop: any) => obj1[prop] === obj2[prop]);
  };

  const similarWidgets = (widgets: any, wid: any) => {
    const similarWidget = widgets.find((existingWidget: any) => {
      return (
        existingWidget.id !== wid.id &&
        areWidgetObjectsSimilar(existingWidget, wid)
      );
    });

    return similarWidget || false;
  };

  const notifySimilar = (countSimilar: number, duplicateWid?: number) => {
    if (countSimilar > 0) {
      openNotificationWithIcon(
        "info",
        `your updates has been ignored, due to duplicate widget: ${duplicateWid}`
      );
    }
    setLoading(false);
  };
  const onFinishAddNewDomain = async (
    domain: Domain,
    widgets: any[],
    InContentThemeColor: Color | string,
    InContentThemeHoverColor: Color | string,
    InContentTitleBGColor: Color | string,
    defaultAiCategory: string | null,
    defaultRevenueSource: string | null,
  ) => {
    setLoading(true);
    countSimilar = 0;
    let duplicateWid;
    let insertWidgets: any[] = [];

    if (user) {
      if (widgets && widgets.length > 0) {
        widgets.map(async (wid: any) => {
          if (similarWidgets(widgets, wid)) {
            countSimilar++;
            duplicateWid = wid.widget_id;
          } else {
            insertWidgets.push(wid);
          }
        });
      }

      if (countSimilar > 0) {
        notifySimilar(countSimilar, duplicateWid);
      } else {
        close();
        let res = await insertDomain(
          domain,
          user,
          pub_key,
          InContentThemeColor.toString(),
          InContentThemeHoverColor.toString(),
          InContentTitleBGColor.toString(),
          defaultAiCategory,
          defaultRevenueSource,
          insertWidgets
        );
        let response = res.data;
        if (!res.data) {
          openNotificationWithIcon("error", "Something went wrong");
          setLoading(false);
        } else if (
          response.status &&
          response.status === "domain name or URL exist"
        ) {
          openNotificationWithIcon(
            "warning",
            "You've already created this Domain name or URL"
          );
          setLoading(false);
        } else {
          close();
          openNotificationWithIcon("success", "Domain added successfully");
          getDomainsData();
        }
      }
    } else {
      openNotificationWithIcon("error", "No user is connected");
      setLoading(false);
    }
  };

  const onFinishEditDomain = async (
    domain: Domain,
    widgets: any[],
    InContentThemeColor: Color | string,
    InContentThemeHoverColor: Color | string,
    InContentTitleBGColor: Color | string,
    defaultAiCategory: string | null,
    widgetsToDelete: any[],
    widgetsOriginalData: any[],
    OriginaldefaultAiCategory?: string,
    defaultRevenueSource?: string | null,
  ) => {
    setLoading(true);
    countSimilar = 0;
    let duplicateWid;
    let updateWidgets: any[] = [];
    let insertWidgets: any[] = [];

    if (user) {
      widgets.map(async (wid: any) => {
        if (!similarWidgets(widgets, wid)) {
          const originalWidget = widgetsOriginalData.find(
            (originalWid: any) => originalWid.id === wid.id
          );
          if (typeof wid.id === "string") {
            insertWidgets.push(wid);
          } else if (
            (originalWidget && !objectsEqual(wid, originalWidget)) ||
            (wid.contentType === "ai" &&
              defaultAiCategory &&
              !objectsEqual(defaultAiCategory, OriginaldefaultAiCategory))
          ) {
            updateWidgets.push(wid);
          }
        } else {
          countSimilar++;
          duplicateWid = wid.widget_id;
        }
      });
      if (countSimilar > 0) {
        notifySimilar(countSimilar, duplicateWid);
      } else {
        close();
        let res = await updateDomain(
          domain,
          user,
          InContentThemeColor.toString(),
          InContentThemeHoverColor.toString(),
          InContentTitleBGColor.toString(),
          defaultAiCategory,
          defaultRevenueSource || "carambola"
        );
        let response = res.data;

        if (!res.data) {
          openNotificationWithIcon("error", "Something went wrong");
          setLoading(false);
        } else if (
          response.status &&
          response.status === "domain name or URL exist"
        ) {
          openNotificationWithIcon(
            "warning",
            "You've already created this Domain name or URL"
          );
          setLoading(false);
        } else {
          const deletePromise = widgetsToDelete.map((wid: any) => {
            deleteWidget(
              domain.public_key,
              wid.ref_pid,
              domain.domain_id,
              wid.id,
              wid.widget_id
            );

            return wid;
          });
          await Promise.all(deletePromise);

          updateWidgets.length &&
            (await updateWidgetBatch(
              updateWidgets,
              domain.domain_id,
              domain.public_key
            ));
          insertWidgets.length &&
            (await insertWidgetBatch(
              insertWidgets,
              domain.domain_id,
              domain.public_key
            ));
          openNotificationWithIcon("success", "Domain updated successfully");
          getDomainsData();
        }
      }
    } else {
      openNotificationWithIcon("error", "No user is connected");
      setLoading(false);
    }
  };

  return (
    <PureCard isInTable={true}>
      {contextHolder}

      <Table
        bordered
        dataSource={data}
        rowKey={"domain_id"}
        columns={columns}
        loading={loading}
        className="custom-table"
      />
      <Button
        permission="update:publishers"
        title="Add Domain"
        type="primary"
        size="large"
        style={{ marginTop: "1.5%" }}
        onClick={() => {
          setShowNewDomain(true);
        }}
      />
      {showNewDomain && (
        <NewDomainModal
          close={close}
          onFinish={onFinishAddNewDomain}
          mode={"new"}
          pub_id={pub_id}
          pub_key={pub_key}
        />
      )}
      {showEditDomain && (
        <NewDomainModal
          close={close}
          onFinish={onFinishEditDomain}
          mode={"edit"}
          pub_id={pub_id}
          data={selectedRecord}
          pub_key={pub_key}
        />
      )}
    </PureCard>
  );
};

export default DomainsTable;
