import React, { useEffect, useState } from "react";
import {
  Row,
  Table,
  Input,
  notification,
  Checkbox 
} from "antd";
import PureCard from "../../components/PureCard/PureCard";
import {
  getPublishersForActiveEdit,
  updatePublisherActive,
} from "../../api/services/Publishers";
import { Publisher } from "../../utils/types";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { NotificationType } from "../../utils/notifictions";
import { IDS } from "../../utils/elementsIds";
import { useAuth0 } from "@auth0/auth0-react";

import "./styles.scss";
import { FormatDateConvert } from "../../utils/utils";

const Publishers = () => {
  const [data, setData] = useState<Publisher[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const { user } = useAuth0();
  const [selectedRecord, setSelectedRecord] = useState<Publisher>(
    {} as Publisher
  );
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);

  useEffect(() => {
    getPublishersData();
  }, []);

  const handleCheckboxChange = async (record : any, checked : any) => {
    const updatedData = data.map((item) =>
      item.pub_id === record.pub_id ? { ...item, is_active: checked } : item
    );
    setData(updatedData);
    let response = await updatePublisherActive(record.pub_id, checked);
  };

  const columns: any = [
    {
      title: "Publisher Id",
      dataIndex: "pub_id",
    },
    {
      title: "Publisher Name",
      dataIndex: "pub_name",
    },
    {
      title: "Create Date",
      dataIndex: "pub_date_created",
      defaultSortOrder: "descend",
      sorter: {
        compare: (a: any, b: any) =>
          new Date(a.pub_date_created).getTime() -
          new Date(b.pub_date_created).getTime(),
      },
    },
    {
      title: (
        <div className="center-column-title">
          <span>Active</span>
        </div>
      ),
      dataIndex: "is_active",
      render: (_: any, record: Publisher) => (
        <Row style={{ justifyContent: "center" }}>
           <Checkbox
          checked={record.is_active}
          onChange={(e) => handleCheckboxChange(record, e.target.checked)}
        />
        </Row>
      ),
    },
  ];
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };

  const getPublishersData = async () => {
    setLoading(true);
    let response = await getPublishersForActiveEdit();
    let originData: any[] = response.map((pub: Publisher) => {
      const dateObject = new Date(pub.publisher_date_created);
      const formattedDate = FormatDateConvert(dateObject);

      return {
        pub_id: pub.pub_id,
        pub_name: pub.pub_name,
        is_active: pub.is_active,
        pub_date_created: formattedDate,
      };
    });
    setData(originData);
    setLoading(false);
  };

  return (
    <PureCard>
      <div className="publishers">
        {contextHolder}
        <Row className="pub-row">
          <div style={{ width: "50%" }}>
            <Input
              addonBefore={<SearchOutlined />}
              size="large"
              id={IDS.PUBLISHERS.PUBLISHER_SEARCH}
              style={{ marginBottom: 24, width: "60%" }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </Row>
        <Table
          bordered
          dataSource={data.filter((record) => {
            return (
              record.pub_name
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              record.websites?.toLowerCase().includes(searchValue.toLowerCase())
            );
          })}
          rowKey={"pub_id"}
          columns={columns}
          loading={loading}
        />
      </div>
    </PureCard>
  );
};

export default Publishers;
