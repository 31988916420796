import client from "../client";
import { Scheduler } from "../../utils/types";
import { HideUnit } from "../../utils/types";

export const updatePosts = async (selectedItems: any[]) => {
  const res = await client.post(`unit/update_posts`, {
    selectedItems: selectedItems,
  });
  return res.data;
};
export const removePosts = async (selectedItemsIds: any[]) => {
  const res = await client.post(`unit/remove_posts`, {
    selectedItemsIds: selectedItemsIds,
  });
  return res.data;
};

export const deleteScheduler = async (selectedItem: any[]) => {
  const res = await client.delete(`unit/scheduler`, {
    data: selectedItem,
  });
  return res.data;
};

export const getSocialData = async (data: any) => {
  const res = await client.get(`unit/socialData`, {
    params: data,
  });

  return res.data;
};

export const getSocialDataByDomain = async (data: any) => {
  const res = await client.get(`unit/socialDataByDomain`, {
    params: data,
  });

  return res.data;
};

export const getLayouts = async () => {
  const res = await client.get(`unit/layouts`);
  return res.data;
};

export const getSocialToolData = async (url: string) => {
  const res = await client.post(`unit/socialTool`, {
    url: url,
  });
  return res.data;
};

export const getSocialOnTheFlyFactsData = async (url: string) => {
  const res = await client.post(`unit/aiOnTheFly/facts`, {
    url: url,
  });
  return res.data;
};

export const getSocialOnTheFlyQuizzData = async (url: string) => {
  const res = await client.post(`unit/aiOnTheFly/quizz`, {
    url: url,
  });
  return res.data;
};

export const setScheduler = async (scheduler: Scheduler) => {
  const res = await client.post(`unit/scheduler`, scheduler);
  return res.data;
};

export const updateScheduler = async (scheduler: Scheduler) => {
  const res = await client.post(`unit/update_scheduler`, scheduler);
  return res.data;
};

export const getAllSchedulers = async () => {
  const res = await client.get(`unit/scheduler`);
  return res.data;
};

export const getSchedulerUrls = async (data: any) => {
  const res = await client.get(`unit/scheduler_urls`, {
    params: data,
  });
  return res.data;
};

export const deleteUrl = async (selectedItem: any[]) => {
  const res = await client.delete(`unit/hide_urls`, {
    data: selectedItem,
  });
  return res.data;
};

export const setUrl = async (data: HideUnit) => {
  const res = await client.post(`unit/hide_urls`, data);
  return res.data;
};

export const updateUrl = async (data: HideUnit) => {
  const res = await client.post(`unit/update_hide_urls`, data);
  return res.data;
};

export const getAllUrls = async () => {
  const res = await client.get(`unit/hide_urls`);
  return res.data;
};