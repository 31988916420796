import { Row, Skeleton, Table } from "antd";
import { useEffect } from "react";
import "./styles.scss";
import { getDataRevenueByDomainSource } from "../../utils/utils";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";

interface DataRevenueByDomainTableProps {
  data: any;
  isLoading: boolean;
}

const DataRevenueByDomainTable = (props: DataRevenueByDomainTableProps) => {
  const { data, isLoading } = props;
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);

  useEffect(() => {}, [data]);

  const getColumns = () => {
    let tmp: any[] = [];
    tmp.push({
      title: "Domain",
      dataIndex: "Domain",
      key: "Domain"
    });
    tmp.push({
      title: "Revenue",
      dataIndex: "Revenue",
      key: "Revenue"
    });
    tmp.push({
      title: "RPM",
      dataIndex: "RPM",
      key: "RPM"
    });
    tmp.push({
      title: "Effective RPM",
      dataIndex: "ERPM",
      key: "ERPM"
    });
    tmp.push({
      title: "Widget Loads",
      dataIndex: "WL",
      key: "WL"
    });
    return tmp;
  };

  return (
    <Row gutter={16}>
      {isLoading || !data ? (
        <Skeleton loading={true} avatar active></Skeleton>
      ) : (
        <Table
          style={{ width: "100%" }}
          dataSource={getDataRevenueByDomainSource(data)}
          columns={getColumns()}
          className={
            isDarkMode ? "table-striped-rows-dark" : "table-striped-rows-light"
          }
          rowKey={"Domain"}
        />
      )}
    </Row>
  );
};

export default DataRevenueByDomainTable;
