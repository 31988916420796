import client from "../client";

export const captureUrl = async (data: any) => {
    const res = await client.post(`/democreator/download`, data);
    return res.data;
};

export const uploadFile = async (file: File, data: Record<string, any>) => {
    const formData = new FormData();
    formData.append('file', file);

    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });

    const res = await client.post('/democreator/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });

    return res.data;
};

export const saveData = async (data: any) => {
    const res = await client.post(`/democreator/save`, data);
    return res.data;
};

export const addPost = async (data: any) => {
    const res = await client.post(`/democreator/add`, data);
    return res.data;
};
