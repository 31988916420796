import Modal from "../components/Modal/Modal";
import { Col, Form, Input, Row } from "antd";
import PublisherSelect from "../pages/DomainSelection/PublisherSelect";
import DomainSelect from "../pages/DomainSelection/DomainSelect";
import Button from "../components/Button";
import { useState } from "react";
import { Mode } from "../utils/types";
import { HideUnit } from "../utils/types";
import { IDS } from "../utils/elementsIds";

interface NewHideUrlModalProps {
  close: () => void;
  onFinish: (data: any) => void;
  mode: Mode;
  data?: HideUnit;
}

const NewHideUrlModal = (props: NewHideUrlModalProps) => {
  const { close, onFinish, mode, data } = props;
  const [dataUrl, setDataUrl] = useState<HideUnit>(data ? {...data } : {} as HideUnit);
  const initialValues = {
    url: dataUrl.url ?? "",
    widget_id: dataUrl.widget_id ?? null
  };

  let final = {};
  if(mode === "new") final = dataUrl; 
  else final = { ...dataUrl };

  return (
    <Modal
      width={"60%"}
      title={<h2>{mode === "new" ? "Add New Hide URL" : "Edit Hide URL"}</h2>}
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
    >
      <Form name="basic" autoComplete="off" layout="vertical" requiredMark onFinish={() => onFinish(final)} initialValues={initialValues} style={{ overflow: "hidden" }}>
        <Row gutter={[24, 24]}>

          {mode === "new" && (<>
          <Col span={8}>
            <Form.Item
              label="Choose Publisher"
              name="publisher"
              rules={[
                {
                  required: !dataUrl.pub_id,
                  message: "Please select publisher",
                },
              ]}
            >
              <PublisherSelect
                id={IDS.NEW_TAG.PUBLISHER_SELECT}
                mode="new"
                onSelect={(pub_id, pub_key) => {
                  setDataUrl({
                    ...dataUrl,
                    pub_id: pub_id,
                    pub_key: pub_key
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Choose Domain"
              name="domain"
              rules={[
                {
                  required: !dataUrl.domain_id,
                  message: "Please select domain",
                },
              ]}
            >
              <DomainSelect
                id={IDS.NEW_TAG.DOMAIN_SELECT}
                onSelect={(value: any) => {
                  setDataUrl({
                    ...dataUrl,
                    domain_id: value,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Type Widget Id"
              name="widget_id"
              rules={[
                {
                  required: true,
                  message: "Please type Widget Id",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Type Widget Id"
                value={dataUrl.widget_id ?? undefined}
                onChange={(e) => {
                  if (e.target.value !== "")
                    setDataUrl({
                      ...dataUrl,
                      widget_id: Number(e.target.value),
                    });
                  else
                    setDataUrl({
                      ...dataUrl,
                      widget_id: null,
                    });
                }}
              ></Input>
            </Form.Item>
          </Col>
          </>
        )}

          <Col span={24}>
            <Form.Item
              label="Type URL"
              name="url"
              rules={[
                {
                  required: true,
                  message: "Please type URL",
                },
              ]}
            >
              <Input
                addonBefore="https://"
                placeholder="Type URL"
                value={dataUrl.url}
                size="large"
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setDataUrl({
                      ...dataUrl,
                      url: e.target.value,
                    });
                  } else {
                    setDataUrl({
                      ...dataUrl,
                      url: "",
                    });
                  }
                }}
              ></Input>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center">
          <Button
            type="primary"
            title={mode === "new" ? "Add URL" : "Edit URL"}
            htmlType="submit"
          ></Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default NewHideUrlModal;
