import { useState } from "react";
import { Row, Skeleton, Input, Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Button from "../../components/Button";
import "../SocialUnitSelect/styles.scss";
import "./styles.scss";

interface SocialUnitProps {
  index: number;
  item: any;
  removePost: (index: number) => void;
  changePost: (index: number, url: string) => void;
  addPost: (index: number) => void;
  checkedPost: (index: number, val: boolean) => void;
}

const SocialUnit = (props: SocialUnitProps) => {
  const { index, item, removePost, changePost, addPost, checkedPost } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [url, setUrl] = useState<any>(null);
  const checked = !item.hasOwnProperty("Checked") ? true : item.Checked;

  const changeObj = (url: string) => {
    if (!url.length) return false;
    let element = { ...item };

    element.InstagramCode = null;
    element.TikTokCode = null;
    element.TwitterXCode = null;

    element.Id = Math.floor(Math.random() * 1000000000);
    if (url.includes("instagram.com")) {
      element.InstagramCode = getInstagram(url);
      element.Type = 16;
    } else if (url.includes("tiktok.com")) {
      element.TikTokCode = `https://www.tiktok.com/embed/v2/${getTiktok(url)}`;
      element.Type = 17;
    } else if (
      url.includes("twitter.com") ||
      url.includes("twitframe.com") ||
      url.includes("x.com")
    ) {
      element.TwitterXCode = url.includes("twitframe.com")
        ? url
        : getTwitter(url);
      element.Type = 18;
    } else {
      return false;
    }

    changePost(index, element);
  };

  const getInstagram = (url: string) => {
    if (url.includes("?")) url = url.slice(0, url.indexOf("?"));
    return url;
  };

  const getTiktok = (url: string) => {
    if (url.includes("?")) url = url.slice(0, url.indexOf("?"));
    const video = url.slice(url.indexOf("/video/") + 7, url.length);
    return video;
  };

  const getTwitter = (url: string) => {
    if (url.includes("?")) url = url.slice(0, url.indexOf("?"));
    url = url.replace("x.com", "twitter.com");
    return `https://twitframe.com/show?url=${url}`;
  };

  const onChange = (e: CheckboxChangeEvent) => {
    checkedPost(index, e.target.checked);
  };

  let code = item?.InstagramCode || item?.TwitterXCode || item?.TikTokCode;
  if (code && code.includes("instagram.com")) {
    code = getInstagram(item.InstagramCode);
    code = code.slice(-1) === "/" ? `${code}embed/` : `${code}/embed/`;
  } else if (
    code &&
    code.length < 100 &&
    (code.includes("twitter.com") ||
      code.includes("twitframe.com") ||
      code.includes("x.com"))
  ) {
    if(code.includes("twitframe.com"))
    {
      let rweet_id = code.split('/').pop();
      if(rweet_id)
      {
        code = `https://platform.twitter.com/embed/Tweet.html?id=${rweet_id}`
      }
      else{
        code = code;
      }
    }
    else{
      code = getTwitter(code);
    }
  }

  return (
    <div
      id={`iframe${index}`}
      style={{
        width: "300px",
        display: "flex",
        flexDirection: "column",
        marginTop: "16px",
      }}
    >
      <Checkbox
        className="check-box"
        defaultChecked={checked}
        onChange={onChange}
      ></Checkbox>
      {(isLoading || code === "") && (
        <Skeleton.Input
          active
          style={{ height: "350px", width: "100%", marginBottom: "24px" }}
        />
      )}

      {code !== "" && (
        <iframe
          style={{ height: "350px" }}
          onLoad={() => setIsLoading(false)}
          className={isLoading ? "iframe-loading" : "iframe-display"}
          src={code}
          title={code}
        ></iframe>
      )}

      <Row
        justify="space-between"
        className="full-width"
        style={{ flexFlow: "initial" }}
      >
        <Input
          placeholder="URL"
          value={url !== null ? url : code}
          onFocus={() => {
            setUrl("");
          }}
          onChange={(e) => {
            setUrl(e.target.value);
          }}
          onBlur={(e) => {
            if (e.target.value) {
              changeObj(e.target.value);
            } else {
              setUrl(null);
            }
          }}
          onPaste={(e) => {
            changeObj(e.clipboardData.getData("Text"));
          }}
        />
        <Button
          type="primary"
          icon={<DeleteOutlined title="Remove post" />}
          style={{ backgroundColor: "#ff4d4f", margin: "0 2px" }}
          size="large"
          className="after-select"
          onClick={() => removePost(index)}
          permission="delete:extensionUpdate"
        />
        <Button
          type="primary"
          icon={<PlusOutlined title="Add new post" />}
          size="large"
          className="after-select"
          onClick={() => addPost(index + 1)}
        />
      </Row>
    </div>
  );
};

export default SocialUnit;
