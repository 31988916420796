import { Layout, Menu } from "antd";
import {
  BarChartOutlined,
  RobotOutlined,
  FileTextOutlined,
  LinkOutlined,
  HeatMapOutlined
} from "@ant-design/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { checkPermissionOk } from "../../utils/utils";

const { Sider } = Layout;

const SideBar = () => {
  const publisher = useSelector((state: AppState) => state.publisher);

  const [collapsed, setCollapsed] = useState(false);
  const permissions = useSelector((state: AppState) => state.permissions); 

  const navigate = useNavigate();

  const checkPermission = (permission: string) => {
    return permissions && checkPermissionOk(permission, permissions);
  };

  const getSideBarItems = () => {
    let items = [];
    items.push({
      key: "/dashboard",
      icon: <BarChartOutlined />,
      label: "Dashboard",
      onClick: () => {
        if (
          permissions &&
          checkPermissionOk("read:domains", permissions) &&
          !publisher?.pub_name
        )
          navigate("/domain-selection");
        else navigate("/dashboard");
      },
    });
    // if (checkPermission("read:unitSelect"))
    //   items.push({
    //     key: "/unit-select",
    //     icon: <FileTextOutlined />,
    //     label: "Unit select - BETA",
    //     children: [
    //       {
    //         key: "/unit-select/explore",
    //         label: "Explore",
    //         onClick: () => {
    //           navigate("/unit-select/explore");
    //         },
    //       },
    //       {
    //         key: "/unit-select/management",
    //         label: "Management",
    //         onClick: () => {
    //           navigate("/unit-select/management");
    //         },
    //       },
    //     ],
    //   });

/*     if (checkPermission("read:adsTxt"))
      items.push({
        key: "/ads-txt",
        icon: <FileTextOutlined />,
        label: "Ads.txt manager",
        onClick: () => {
          navigate("/ads-txt");
        },
      }); */

    if (checkPermission("read:advertisers"))
      items.push({
        key: "/advertisers",
        icon: <FileTextOutlined />,
        label: "Advertisers",
        onClick: () => {
          navigate("/advertisers");
        },
      });
/*     if (checkPermission("read:socialTool"))
      items.push({
        key: "/social-tool",
        icon: <FileTextOutlined />,
        label: "Social tool",
        onClick: () => {
          navigate("/social-tool");
        },
      }); */
    if (checkPermission("read:extensionUpdate"))
      items.push({
        key: "/extension-update",
        icon: <RobotOutlined />,
        label: "Extension Update",
        onClick: () => {
          navigate("/extension-update");
        },
      });
    if (checkPermission("read:publishers"))
      items.push({
        key: "/publishers",
        icon: <FileTextOutlined />,
        label: "Publishers",
        onClick: () => {
          navigate("/publishers");
        },
      });
    if (checkPermission("read:publishers"))
      items.push({
        key: "/active-publishers",
        icon: <FileTextOutlined />,
        label: "Active Publishers",
        onClick: () => {
          navigate("/active-publishers");
        },
      });
    if (checkPermission("read:scheduler"))
      items.push({
        key: "/scheduler",
        icon: <FileTextOutlined />,
        label: "Scheduler",
        onClick: () => {
          navigate("/scheduler");
        },
      });
    if (checkPermission("read:providers"))
      items.push({
        key: "/providers/management",
        icon: <FileTextOutlined />,
        label: "Providers",
        onClick: () => {
          navigate("/providers/management");
        },
    });
      if (checkPermission("read:adjustments"))
        items.push({
          key: "/adjustments",
          icon: <FileTextOutlined />,
          label: "Adjustments",
          onClick: () => {
            navigate("/adjustments");
          },
      });
      if (checkPermission("read:demoCreator"))
        items.push({
          key: "/demo-creator",
          icon: <FileTextOutlined />,
          label: "Demo Creator",
          onClick: () => {
            navigate("/demo-creator");
          },
      });
      if (checkPermission("read:hideUnit"))
        items.push({
          key: "/hide-unit",
          icon: <LinkOutlined />,
          label: "Hide Unit by URL",
          onClick: () => {
            navigate("/hide-unit");
          },
      });
      if (checkPermission("read:socialFeedMapping"))
        items.push({
          key: "/social-feed-mapping",
          icon: <HeatMapOutlined />,
          label: "Social Feed Mapping",
          onClick: () => {
            navigate("/social-feed-mapping");
          },
      });
    return items;
  };

  return (
    <Sider
      width={230}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="logo" />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        style={{ marginTop: "60px" }}
        items={getSideBarItems()}
        selectedKeys={[new URL(window.location.href).pathname]}
      ></Menu>
    </Sider>
  );
};
export default SideBar;
