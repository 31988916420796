import Modal from "../components/Modal/Modal";
import { Form, InputNumber, Row, Checkbox } from "antd";
import Select from "../components/Select/Select";
import Button from "../components/Button";
import { useEffect, useState } from "react";
import { Mode } from "../utils/types";
import Input from "antd/es/input/Input";
import "./styles.scss";
import { Publisher } from "../utils/types";
import TextArea from "antd/es/input/TextArea";
import AlertModal from "./AlertModal";
import { validatorInput } from "../utils/utils";

interface NewPublisherModalProps {
  close: () => void;
  onFinish: (publisher: any) => void;
  data?: Publisher;
  mode: Mode;
}

const PublisherModal = (props: NewPublisherModalProps) => {
  const { data, mode, close, onFinish } = props;
  const defaultPublisher = {
    pub_id: 0,
    pub_name: "",
    public_key: "",
    is_active: false,
    publisher_date_created: new Date(),
    pub_contact_name: "",
    pub_email: "",
    contract_type: 3,
    cpm: 0,
    location: "",
    terms_of_payment_in_days: 0,
    dashboard_popup_message_url: "",
    InCorporated: false,
    websites: "",
    publisher_cpm: null,
    cpm_start_date: null,
    publisher_serving_fee: null,
    revenue_source: "bi",
    display_revenue_by_domain: false,
  };
  const [publisher, setPublisher] = useState<Publisher>(defaultPublisher);
  const [showCheckEditModal, setShowCheckEditModal] = useState<boolean>(false);
  const [initialPublisher, setInitialPublisher] =
    useState<Publisher>(defaultPublisher);

  useEffect(() => {
    if (data) {
      const roundedCpm = Math.round(data.cpm * 100 * 100) / 100;
      setInitialPublisher({ ...data, cpm: roundedCpm });
      setPublisher({ ...data, cpm: roundedCpm });
    }
  }, [data]);

  return (
    <Modal
      width={"40%"}
      title={<h2>{mode === "new" ? "Add New Publisher" : "Edit Publisher"}</h2>}
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
      className="publisher-modal"
    >
      {(publisher.pub_id || mode === "new") && (
        <Form
          name="publisher-modal"
          onFinish={() => {
            if (mode === "edit") {
              const isDataChanged =
                JSON.stringify(initialPublisher) !== JSON.stringify(publisher);

              if (isDataChanged) {
                setShowCheckEditModal(true);
              } else {
                close();
              }
            } else {
              onFinish(publisher);
            }
          }}
          onFinishFailed={() => {}}
          autoComplete="off"
          layout="vertical"
          requiredMark
          initialValues={{
            pub_name: publisher.pub_name ?? "",
            cpm: publisher.cpm ?? 0,
            contract_type: publisher.contract_type ?? 3,
            pub_contact_name: publisher.pub_contact_name ?? "",
            pub_email: publisher.pub_email ?? "",
            terms_of_payment_in_days: publisher.terms_of_payment_in_days ?? 0,
            location: publisher.location ?? "",
            InCorporated: publisher.InCorporated,
            dashboard_popup_message_url: publisher.dashboard_popup_message_url,
            publisher_cpm: publisher.publisher_cpm ?? null,      
            cpm_start_date: publisher.cpm_start_date ?? null,      
            publisher_serving_fee: publisher.publisher_serving_fee ?? null,
            revenue_source: publisher.revenue_source ?? null,
            display_revenue_by_domain: publisher.display_revenue_by_domain ?? 0,      
          }}
        >
          <Row justify="space-between">
            <Form.Item
              label="Choose Name"
              name="pub_name"
              rules={[
                { required: true, message: "Please Choose Publisher Name" },
                {
                  validator: async (_, value) => {
                    await validatorInput(value, "Name", 35);
                  },
                },
              ]}
              className="form-item"
            >
              <Input
                placeholder="Please Choose Publisher Name"
                disabled={mode === "edit"}
                size="large"
                value={publisher.pub_name}
                onChange={(e) => {
                  setPublisher({
                    ...publisher,
                    pub_name: e.target.value.trim(),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Choose Contract Type"
              name="contract_type"
              required
              rules={[
                { required: true, message: "Please Select Contract Type" },
              ]}
              className="form-item"
            >
              <Select
                allowClear
                placeholder="Please Select Contract Type"
                options={[
                  { label: "Rev Share", value: 3 },
                  { label: "Tech Fee", value: 4 },
                  { label: "CPM", value: 5 },
                  { label: "Serving Fee", value: 6 },
                ]}
                value={publisher.contract_type.toString()}
                onSelect={(value) => {
                  setPublisher({
                    ...publisher,
                    contract_type: value,
                  });
                }}
              />
            </Form.Item>
          </Row>

          <Row justify="space-between">
            <Form.Item
              label="Contact Name"
              name="pub_contact_name"
              className="form-item"
              rules={[
                {
                  validator: (_, value) => {
                    if (value.length <= 35) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Name is too long");
                  },
                },
              ]}
            >
              <Input
                placeholder="Please Type Contact Name"
                value={publisher.pub_contact_name}
                size="large"
                onChange={(e) => {
                  setPublisher({
                    ...publisher,
                    pub_contact_name: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Contact Email"
              name="pub_email"
              className="form-item"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
                {
                  validator: (_, value) => {
                    if (value.length <= 40) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Email is too long");
                  },
                },
              ]}
            >
              <Input
                placeholder="Please Type Contact Email"
                value={publisher.pub_email ?? ""}
                maxLength={40}
                size="large"
                onChange={(e) => {
                  setPublisher({
                    ...publisher,
                    pub_email: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </Row>
          <Row justify="space-between">
            <Form.Item
              label="Rev Share %"
              name="cpm"
              className="form-item"
              rules={[
                { type: "number", message: "Please enter a valid number" },
                {
                  validator: (_, value) => {
                    if (publisher.cpm === 0 || (value >= 0 && value <= 100)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Rev Share percentage must be between 0 and 100"
                    );
                  },
                },
              ]}
            >
              <InputNumber
                className="full-width"
                value={publisher.cpm}
                size="large"
                onChange={(value: any) => {
                  setPublisher({ ...publisher, cpm: value });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Terms Of Payment (Days)"
              name="terms_of_payment_in_days"
              className="form-item"
              rules={[
                { type: "number", message: "Please enter a valid number" },
                {
                  validator: (_, value) => {
                    if (value >= 0 && value < 9999) {
                      return Promise.resolve();
                    }
                    return Promise.reject("value is not valid");
                  },
                },
              ]}
            >
              <InputNumber
                className="full-width"
                size="large"
                value={publisher.terms_of_payment_in_days}
                onChange={(value: any) => {
                  setPublisher({
                    ...publisher,
                    terms_of_payment_in_days: value,
                  });
                }}
              />
            </Form.Item>
          </Row>


          <Row justify="space-between">
            <Form.Item
              label="CPM"
              name="publisher_cpm"
              className="form-item"
              rules={[
                { type: "number", message: "Please enter a valid number" }
              ]}
            >
              <InputNumber
                className="full-width"
                value={publisher.publisher_cpm}
                size="large"
                onChange={(value: any) => {
                  setPublisher({ ...publisher, publisher_cpm: value });
                }}
              />
            </Form.Item>


            { publisher.contract_type === 5 &&
              <Form.Item
                label="CPM Start Date"
                name="cpm_start_date"
                className="form-item"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value.length <= 20) {
                        return Promise.resolve();
                      }
                      return Promise.reject("CPM Start Date is too long");
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Please Type CPM Start Date(yyyy-MM-dd)"
                  value={publisher.cpm_start_date}
                  size="large"
                  onChange={(e) => {
                    setPublisher({
                      ...publisher,
                      cpm_start_date: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            }

          </Row>


          <Row justify="space-between">
          <Form.Item
              label="Serving Fee"
              name="publisher_serving_fee"
              className="form-item"
              rules={[
                { type: "number", message: "Please enter a valid number" }
              ]}
            >
              <InputNumber
                className="full-width"
                size="large"
                value={publisher.publisher_serving_fee}
                onChange={(value: any) => {
                  setPublisher({ ...publisher, publisher_serving_fee: value });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Revenue Source"
              name="revenue_source"
              className="form-item"
            >
              <Select
                allowClear
                placeholder="Please Select Revenue Source"
                options={[
                  { label: "BI", value: "bi" },
                  { label: "Adjustments", value: "adjustments" }
                ]}
                value={publisher.revenue_source}
                
                onSelect={(value) => {
                  setPublisher({
                    ...publisher,
                    revenue_source: value
                  });
                }}
              />
            </Form.Item>
          </Row>

          <Row justify="space-between">
          <Form.Item
              label="Choose Location"
              name="location"
              className="form-item"
              rules={[
                {
                  validator: (_, value) => {
                    if (value.length <= 20) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Location is too long");
                  },
                },
              ]}
            >
              <Input
                placeholder="Please Type Location"
                size="large"
                onChange={(e) => {
                  setPublisher({
                    ...publisher,
                    location: e.target.value,
                  });
                }}
                value={publisher.location ?? ""}
              />
            </Form.Item>
            <Form.Item
              name="InCorporated"
              className="form-item"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <div>
                <span style={{ marginRight: 20 }}> Is EU In Corporated </span>
                <Checkbox
                  onChange={(e) => {
                    setPublisher({
                      ...publisher,
                      InCorporated: e.target.checked,
                    });
                  }}
                  checked={publisher.InCorporated}
                />
              </div>
            </Form.Item>
            <Form.Item
              name="display_revenue_by_domain"
              className="form-item"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <div>
                <span>Display Revenue By Domain</span>
                <Checkbox
                  onChange={(e) => {
                    setPublisher({
                      ...publisher,
                      display_revenue_by_domain: e.target.checked,
                    });
                  }}
                  checked={publisher.display_revenue_by_domain}
                />
              </div>
            </Form.Item>
          </Row>



          {mode === "edit" && (
            <Row justify="space-between">
              <Form.Item
                label="Dashboard Popup Message"
                name="dashboard_popup_message_url"
                style={{ width: "100%" }}
              >
                <TextArea
                  onChange={(e) => {
                    setPublisher({
                      ...publisher,
                      dashboard_popup_message_url: e.target.value,
                    });
                  }}
                  value={publisher.pub_name}
                />
              </Form.Item>
            </Row>
          )}

          <Row style={{ justifyContent: "center", gap: "16px" }}>
            <Button type="default" title="Cancel" onClick={close}></Button>
            <Button
              type="primary"
              title={"Save"}
              onClick={() => {}}
              htmlType="submit"
            ></Button>
          </Row>
        </Form>
      )}
      {showCheckEditModal && (
        <AlertModal
          alertText="Are you sure you want to edit?"
          titleOnOK="SAVE CHANGES"
          titleOnCancle="CANCEL"
          onClose={() => {
            setShowCheckEditModal(false);
          }}
          isModalOpen={showCheckEditModal}
          onOk={() => {
            setShowCheckEditModal(false);
            onFinish(publisher);
          }}
        />
      )}
    </Modal>
  );
};

export default PublisherModal;
